import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { FormatService } from './format.service';

@Injectable({ providedIn: 'root' })
export class UserService {
  constructor(private http: HttpClient, private formatService: FormatService) { }

  register(requestBody: any) {
    requestBody = this.formatService.formatDatesInRequestBody(requestBody);
    return this.http.post(`${environment.apiUrl}users/register`, requestBody);
  }

  checkFirstValidation(requestBody: any) {
    requestBody = this.formatService.formatDatesInRequestBody(requestBody);
    return this.http.post(`${environment.apiUrl}users/register-first-validation`, requestBody);
  }

  checkSecondValidation(requestBody: any) {
    requestBody = this.formatService.formatDatesInRequestBody(requestBody);
    return this.http.post(`${environment.apiUrl}users/register-second-validation`, requestBody);
  }

  refreshPassword(requestBody: any) {
    requestBody = this.formatService.formatDatesInRequestBody(requestBody);
    return this.http.patch(`${environment.apiUrl}users/password-forgot`, requestBody);
  }

  /** Change password when user forgot it */
  changePassword(requestBody: any) {
    requestBody = this.formatService.formatDatesInRequestBody(requestBody);
    return this.http.patch(`${environment.apiUrl}users/change-password`, requestBody);
  }

  /** Change password when user requested it */
  changeUserPassword(requestBody: any) {
    requestBody = this.formatService.formatDatesInRequestBody(requestBody);
    return this.http.patch(`${environment.apiUrl}users/change-user-password`, requestBody);
  }

  /** Change user information per user request */
  patchUserInfo(requestBody: any) {
    requestBody = this.formatService.formatDatesInRequestBody(requestBody);
    return this.http.patch(`${environment.apiUrl}users/patch-user-info`, requestBody);
  }
}
