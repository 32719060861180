import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class DiagnosticianService {
  private diagnosticianSubject: BehaviorSubject<any>;
  public diagnostician: Observable<any>;

  constructor(private http: HttpClient) {
    this.diagnosticianSubject = new BehaviorSubject<any>(null);
    this.diagnostician = this.diagnosticianSubject.asObservable();
  }

  public get diagnosticianValue(): any {
    return this.diagnosticianSubject.value;
  }

  public set diagnosticianValue(value: any) {
    this.diagnosticianSubject.next(value);
  }

  async getLoggedDiagnosticianPhoto(): Promise<any> {
    return <any>(await this.http.get(`${environment.apiUrl}diagnosticians/getLoggedPhoto/`).toPromise());
  }

  async getLoggedDiagnosticianInfo():Promise<any> {
    return <any>(await this.http.get(`${environment.apiUrl}diagnosticians/getDiagnosticianInfo`).toPromise());
  }

}
