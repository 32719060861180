import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { environment } from '../../environments/environment';
import { UserService } from '../_services/user.service';
import { FormGroup, FormBuilder, Validators, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { validatePolish } from 'validate-polish';
import { first } from 'rxjs/operators';

@Component({
  templateUrl: './register.component.html'
})
export class RegisterComponent implements OnInit {

  agreement: boolean;
  agreement2: boolean;
  agreement3: boolean;

  items: MenuItem[];
  error: string;
  activeIndex = 0;
  private diagnosticianId: any;
  form: FormGroup;
  verifyAccountForm: FormGroup;
  formFinal: FormGroup;
  submitted = false;
  loading = false;
  displayCompleteDialog = false;
  needsActivation:any = false;
  readonly dialogContent = "Twoje konto zostało założone. Zalogujesz się do niego przy użyciu swojego numeru PWZDL (Login) oraz podanego hasła. Po zamknięciu tego okna zostaniesz przekierowany na stronę logowania.";
  readonly dialogContentUnactiv = "Twoje konto zostało założone, jednak wymaga akceptacji. Zalogujesz się do niego przy użyciu swojego numeru PWZDL (Login) oraz podanego hasła. Po zamknięciu tego okna zostaniesz przekierowany na stronę logowania.";
  readonly passwordMinLenght: number = environment.passwordMinLenght;

  constructor(
    private formBuilder: FormBuilder,
    private service: UserService) { }

  ngOnInit(): void {
    this.items = [
      { label: 'Wstępna weryfikacja' },
      { label: 'Potwierdzenie danych' },
      { label: 'Zatwierdzenie danych' }
    ];

    this.form = this.formBuilder.group({
      nrPWZDL: ['', Validators.required],
      diagnosticianOrginalId: ['', Validators.required],
      pesel: ['', [Validators.required, Validators.minLength(11), Validators.maxLength(11),this.peselValidator()]]
    });

    this.verifyAccountForm = this.formBuilder.group({
      subAccountPatternPrefix: ['', [Validators.required, Validators.pattern('^[0-9]{2}$'), Validators.minLength(2), Validators.maxLength(2)]],
      subAccountPatternSufix: ['', [Validators.required, Validators.pattern('^[0-9]{6}$'), Validators.minLength(6), Validators.maxLength(6)]]
    });

    this.formFinal = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      email2: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.pattern(environment.passwordRegex), Validators.required, Validators.minLength(environment.passwordMinLenght)]],
      password2: ['', [Validators.pattern(environment.passwordRegex), Validators.required, Validators.minLength(environment.passwordMinLenght)]]
    });
  }
  peselValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const wrongPesel = !validatePolish.pesel(control.value);
      return wrongPesel ? {forbiddenName: {value: control.value}} : null;
    };
  }
  sendData(stepIndex: number): void {
    this.loading = true;
    this.error = null;
    if (stepIndex == 0) {
      this.submitted = !this.form.valid;
    }
    else if (stepIndex == 1) {
      this.submitted = !this.verifyAccountForm.valid;

    }
    else if (stepIndex == 2) {
      this.submitted = !this.formFinal.valid;

      if (this.ff.password.value !== this.ff.password2.value) {
        this.error = "Hasła nie są jednakowe!";
      }
      else if (this.ff.email.value !== this.ff.email2.value)  {
        this.error = "Adresy mail nie są jednakowe!";
      }
      else if (!this.agreement || !this.agreement2) {
        this.error = "Prosimy wyrazić obligatoryjne zgody!";
      }
    }
    if (this.submitted || this.error) {
      this.loading = false;
      return;
    }
    const body = {
      email: this.ff.email.value,
      password: this.ff.password.value,
      pwzdlnumber: this.f.nrPWZDL.value,
      diagnosticianOrginalId: this.f.diagnosticianOrginalId.value,
      pesel: this.f.pesel.value,
      diagonisticianid: this.diagnosticianId,
      agreement: this.agreement3
    }

    this.submitted = false;

    switch(stepIndex) {
      case 0:
        this.checkFirstValidation(body);
        break;
      case 1:
        this.checkSecondValidation();
        break;
      case 2:
        this.register(body);
        break;
    }
  }

  get f() { return this.form.controls; }
  get ff() { return this.formFinal.controls; }
  get vf() { return this.verifyAccountForm.controls; }

  moveToStep(stepIndex: number): void {
    this.error = "";
    this.activeIndex = stepIndex;
  }

  private register(requestBody: any): void {
    this.service.register(requestBody)
      .pipe()
      .subscribe({
        next: (data) => {
          this.needsActivation = data;
          this.displayCompleteDialog = true;
        },
        error: error => {
          this.loading = false;
          this.error = error;
        },
      });
  }

  private checkFirstValidation(requestBody: any): void {
    this.service.checkFirstValidation(requestBody)
      .pipe()
      .subscribe({
        next: (data) => {
          this.diagnosticianId = data;
          this.loading = false;
          this.moveToStep(1);
        },
        error: error => {
          this.loading = false;
          this.error = error;
        }
      });
  }

  private checkSecondValidation(): void {
    const body = {
      pwzdlnumber: this.f.nrPWZDL.value,
      diagnosticianOrginalId: this.f.diagnosticianOrginalId.value,
      pesel: this.f.pesel.value,
      subAccountPattern: this.vf.subAccountPatternPrefix.value + this.vf.subAccountPatternSufix.value
    }

    this.loading = true;
    this.service.checkSecondValidation(body)
      .pipe(first())
      .subscribe({
        next: () => {
          this.moveToStep(2);
          this.loading = false;
        },
        error: (error: string) => {
          this.loading = false;
          this.error = error;
        }
      });
  }
}
