import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { LazyLoadEvent } from 'primeng/api';

@Injectable({ providedIn: 'root' })
export class PostService {
  constructor(private http: HttpClient) {
  }

  async getPostsLazy(event : LazyLoadEvent): Promise<any> {
    let obj = {
      lazyEvent: JSON.stringify(event)
    };
    return <any>await this.http.get(`${environment.apiUrl}posts`, { params: obj }).toPromise();
  }

}
