<div class="page">
  <h5 class="segmentheader">WNIOSKI</h5>
  <hr />
  <p-dialog [closable]="false" header="Dziękujemy!" [(visible)]="displayCompleteDialog"
    [breakpoints]="{'960px': '75vw'}" [style]="{width: '50vw'}" [baseZIndex]="10000" [draggable]="false"
    [resizable]="false">
    <span class="blue">Twój wniosek został do nas wysłany.<br />Wysłany wniosek oraz informację o decyzji będziesz mógł
      odnaleźć w zakładce Moje dokumenty.</span>
    <ng-template pTemplate="footer">
      <p-button autofocus (click)="displayCompleteDialog = false" label="Powrót" styleClass="p-button-text"></p-button>
    </ng-template>
  </p-dialog>
  <p-dialog [closable]="false" [(visible)]="displayErrorDialog" [breakpoints]="{'960px': '75vw'}"
    [style]="{width: '50vw'}" [baseZIndex]="10000" [draggable]="false" [resizable]="false">
    <ng-template pTemplate="header">
      <div>
        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
          <g id="Group_15305" data-name="Group 15305" transform="translate(-569 -266)">
            <circle id="Ellipse_9" data-name="Ellipse 9" cx="25" cy="25" r="20" transform="translate(569 266)"
              fill="#fcc" />
            <text id="i" transform="translate(589 292) rotate(360)" fill="#fa1a1a" font-size="34"
              font-family="Halcyon-SemiBold, Halcyon" font-weight='800' letter-spacing="0.03em">
              <tspan x="0.167" y="10">!</tspan>
            </text>
          </g>
        </svg>
        <br />
        <br />
        Plik, który próbujesz dodać, ma zły format lub jest zbyt duży.
      </div>
    </ng-template>
    <span class="blue">Upewnij się, że plik jest w formacie .JPG, .PNG, .PDF, .DOC, .DOCX, .TXT, .XLS, .XLSX o wadze
      poniżej 6 MB.
      Możesz dodać maksymalnie 10 załączników.</span>
    <ng-template pTemplate="footer">
      <p-button autofocus (click)="displayErrorDialog = false" label="Powrót" styleClass="p-button-text"></p-button>
    </ng-template>
  </p-dialog>
  <div class="col">
    <div [formGroup]="form" class="grid shadow-5 whitearea">
      <div class="col-12">
        <label class="tableheader tabletitle mb-2 ml-1 mt-4">Wniosek</label>
      </div>
      <div class="md:col-5 col-12">
        <span class="p-float-label">
          <p-dropdown formControlName="title" [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.title.errors }"
            id="float-dd" [autoDisplayFirst]="false" [options]="ApplicationTypes" optionLabel="title"
            (onChange)="onApplicationTitleChange($event)">
          </p-dropdown>
          <label for="float-dd">Temat wniosku</label>
        </span>
        <div [ngStyle]="submitted && f.title.errors && {'display':'block'}" class="invalid-feedback">
          <div *ngIf="f.title.errors?.required">Temat jest wymagany.</div>
        </div>
      </div>
      <div class="col-12 mt-3">
        <span class="p-float-label">
          <textarea formControlName="content" [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.content.errors }"
            id="float-input" rows="4" class="input w-full" pInputTextarea></textarea>
          <label for="float-input">Treść</label>
        </span>
        <div [ngStyle]="submitted && f.content.errors && {'display':'block'}" class="invalid-feedback">
          <div *ngIf="f.content.errors?.required">Treść jest wymagana.</div>
        </div>
      </div>

      <div class="col-12" *ngIf="f.title.value?.requiresAttachment === true">
        <app-diag-applications-data-templates></app-diag-applications-data-templates>
      </div>

      <div class="col-12 md:col-6 mt-3">
        <p-fileUpload #attachmentUpload (onSelect)="onSelect($event)" [fileLimit]="10" [maxFileSize]="6000000"
          [auto]="true" [url]="endpointUrl" (uploadHandler)="uploadFiles($event)" [customUpload]="true"
          accept=".pdf, .doc, .docx, .txt, .xlsx, .xls, .png, .jpg" multiple="multiple">
          <ng-template pTemplate="content" let-files>
            <div style="width:50%;margin-right:0;margin-left:auto"><span style="cursor:pointer" (click)="add()"
                class="blue">DODAJ</span> lub przeciągnij w to miejsce pliki, które chcesz załączyć do wniosku.
              Dozwolony format to .JPG, .PNG, .PDF, .DOC, .DOCX, .TXT, .XLS, .XLSX o wadze poniżej 6 MB.</div>
          </ng-template>
        </p-fileUpload>
      </div>
      <div class="col-12 md:col-6 mt-3">
        <span class="blue">Załączone pliki:</span>
        <ng-container *ngFor="let attachment of uploadedFiles">
          <div class="attachment mt-2">
            <img class="ml-2 mr-2" src="/assets/ikony pozostale/zalacznik.svg">
            <label class="flex-grow-1 text-break">{{attachment.name}}</label>
            <i (click)="deleteAttach(attachment)"
              style="height:1%;cursor:pointer;border-radius:15px;background:#FEC3C3;color:#FA1A1A;padding:3px"
              class="pi pi-times mr-2"></i>
          </div>
        </ng-container>
      </div>
      <div class="col-12 text-end mb-3 mt-3">
        <button (click)="send()" pButton type="button" label="Wyślij"></button>
      </div>
    </div>
  </div>
</div>
