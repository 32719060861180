import { ChangeDetectorRef, Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { environment } from '../environments/environment';
import { PrimeNGConfig, Translation } from 'primeng/api';
import { User } from './_models/user';
import { ProgressSpinner } from 'primeng/progressspinner';
import { NavigationStart, Router } from '@angular/router';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { OverlayPanel } from 'primeng/overlaypanel';
import { AuthenticationService } from './_services/authentication.service';
import { DiagnosticianService } from './_services/diagnostician.service';
import { SystemMessageService } from './_services/system-message.service';
import { ToastService } from './_services/toast.service';
import { ConfigService } from './_services/config.service';
import { Config } from './_models/config';

@Component({
  selector: 'app',
  templateUrl: 'app.component.html'
})

export class AppComponent implements OnInit {
  public config: Config;
  user: User;
  location: Location;
  display: boolean = false;
  idleState = "NOT_STARTED";
  diagnosticianPhoto: string;
  diagnosticianName: string;
  messages: boolean;

  @ViewChild('mask') mask: ProgressSpinner;
  @ViewChild('op') op: OverlayPanel;
  @ViewChild('op2') op2: OverlayPanel;

  constructor(
    private diagService: DiagnosticianService,
    private systemMessageService: SystemMessageService,
    private idle: Idle,
    cd: ChangeDetectorRef,
    private toastService: ToastService,
    public authenticationService: AuthenticationService,
    private primengConfig: PrimeNGConfig,
    private router: Router,
    private configService: ConfigService) {
    this.authenticationService.user.subscribe(x => this.user = x);
    this.toastService.mask.subscribe(x => this.display = x);
    router.events.subscribe((val) => {
      if (val instanceof NavigationStart)
        this.toastService.putOutMask()
    });
    idle.setIdle(1500); // how long can they be inactive before considered idle, in seconds
    idle.setTimeout(1800); // how long can they be idle before considered timed out, in seconds
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    idle.onIdleStart.subscribe(() => {
      if (this.user)
        this.toastService.addInfo("Użytkownik nieaktywny! Za 5 minut nastąpi wylogowanie.", 20);
    });
    idle.onIdleEnd.subscribe(() => {
      this.idleState = "NOT_IDLE";
      cd.detectChanges();
    });
    idle.onTimeout.subscribe(() => {
      if (this.user) {
        this.logout();
      }
    });
  }

  hideOp() {
    this.op?.hide();
    this.op2?.hide();
  }

  @HostListener('window:scroll', ['$event']) // for window scroll events
  onScroll() {
    this.hideOp();
  }

  reset() {
    this.idle.watch();
    this.idleState = "NOT_IDLE";
  }

  open(url) {
    window.open(url)
  }

  ngOnInit() {
    this.reset();
    this.primengConfig.setTranslation(this.translation);
    this.systemMessageService.messages.subscribe(x => { this.messages = x?.filter(x => x.isRead === false).length > 0; });
    this.authenticationService.user.subscribe(x => {
      if (x) {
        this.systemMessageService.getMessages(this.authenticationService.userValue.id).subscribe();
        this.diagService.getLoggedDiagnosticianPhoto().then((photo) => { this.diagnosticianPhoto = photo.base64string !== "" ? 'data:image/jpeg;base64,' + photo.base64string : ""; });
        this.diagService.getLoggedDiagnosticianInfo().then((diag) => { this.diagnosticianName = diag.firstName + " " + diag.lastName; });
      }
    });
    if (environment.production) {
      if (location.protocol === 'http:')
        window.location.href = location.href.replace('http', 'https');
    }

    this.config = this.configService.getConfig()
    if (this.user && !this.config.loginEnabled) {
      this.logout();
    }
  }

  get url() {
    return this.router.url;
  }

  logout() {
    this.hideOp();
    this.authenticationService.logout();
  }

  navigate(target) {
    window.open(target, "_blank");
  }

  private translation: Translation = {
    "startsWith": "Zaczyna się na",
    "contains": "Zawiera",
    "notContains": "Nie zawiera",
    "endsWith": "Kończy się na",
    "equals": "Równe",
    "notEquals": "Nie równe",
    "noFilter": "Bez filtra",
    "lt": "Mniej niż",
    "lte": "Nie więcej niż",
    "gt": "Więcej niż",
    "gte": "Nie mniej niż",
    "is": "Jest",
    "isNot": "Nie jest",
    "before": "Przed",
    "after": "Po",
    "clear": "Wyczyść",
    "apply": "Zastosuj",
    "matchAll": "Dopasuj wszystko",
    "matchAny": "Dopasuj cokolwiek",
    "addRule": "Dodaj zasadę",
    "removeRule": "Usuń zasadę",
    "accept": "TAK",
    "reject": "NIE",
    "choose": "WYBIERZ",
    "upload": "WYŚLIJ",
    "cancel": "ANULUJ",
    "dayNames": ["Niedziela", "Poniedziałek", "Wtorek", "Środa", "Czwartek", "Piątek", "Sobota"],
    "dayNamesShort": ["niedz.", "pon.", "wt.", "śr.", "czw.", "pt.", "sob."],
    "dayNamesMin": ["niedz.", "pon.", "wt.", "śr.", "czw.", "pt.", "sob."],
    "monthNames": ["Styczeń", "Luty", "Marzec", "Kwiecień", "Maj", "Czerwiec", "Lipiec", "Sierpień", "Wrzesień", "Październik", "Listopad", "Grudzień"],
    "monthNamesShort": ["STY", "LUT", "MAR", "KWI", "MAJ", "CZE", "LIP", "SIE", "WRZ", "PAŹ", "LIS", "GRU"],
    "today": "Dziś",
    "weekHeader": "tydz.",
    "weak": "Słabe",
    "medium": "Umiarkowane",
    "strong": "Silne",
    "passwordPrompt": "Podaj hasło",
    "emptyMessage": "Nie znaleziono wyników",
    "emptyFilterMessage": "Nie znaleziono wyników",
    "dateIs": "Data równa",
    "dateIsNot": "Data różna",
    "dateBefore": "Data wcześniejsza",
    "dateAfter": "Data późniejsza",
  }
}
