import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DialogModule } from 'primeng/dialog';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputMaskModule } from 'primeng/inputmask';
import { first } from 'rxjs/operators';
import { AuthenticationService } from '@app/_services/authentication.service';

@Component({
  selector: 'app-login-verifier',
  templateUrl: './login-verifier.component.html',
})
export class LoginVerifierComponent implements OnInit {
  @Input() isUserVerified = true; // domyślnie na true - podczas logowania zostanie sprawdzone
  @Input() username: string;
  @Output() isUserVerifiedChange = new EventEmitter<boolean>();

  verifyAccountForm: FormGroup;
  submitted: boolean;
  loading: boolean;
  error = '';

  constructor(private formBuilder: FormBuilder, private authenticationService: AuthenticationService) { }

  ngOnInit(): void {
    this.verifyAccountForm = this.formBuilder.group({
      diagnosticianOriginalId: [null, [Validators.required, Validators.min(1), Validators.max(999999)]], // wpis na listę - póki co 20k
      subAccountStartPattern: ['', [Validators.required, Validators.pattern('^[0-9]{2}$')]], // same cyfry - 2 długość
      subAccountEndPattern: ['', [Validators.required, Validators.pattern('^[0-9]{6}$')]] // same cyfry - 6 długość
    });
  }

  get f() { return this.verifyAccountForm.controls; }

  verifyUser() {
    this.isUserVerified = true;
    this.error = '';
    this.isUserVerifiedChange.emit(this.isUserVerified);
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.verifyAccountForm.invalid) {
      return;
    }

    this.loading = true;
    this.authenticationService.verify(
      this.username,
      this.f.diagnosticianOriginalId.value,
      this.f.subAccountStartPattern.value + this.f.subAccountEndPattern.value)
      .pipe(first())
      .subscribe({
        next: (response: string) => {
          this.verifyUser();
          this.loading = false;
        },
        error: (error: any) => {
          this.loading = false;
          this.error = error.name == "EmptyError" ? this.error : error.message || error;
        }
      });
  }
}
