<p-dialog [closable]="false" header="Zmiana hasła" [(visible)]="displayCompleteDialog" [breakpoints]="{'960px': '75vw'}"
  [style]="{width: '50vw'}" [baseZIndex]="10000" [draggable]="false" [resizable]="false">
  <p>Twoje hasło zostało zmienione. Po zamknięciu tego okna zostaniesz przekierowany na stronę logowania.</p>
  <ng-template pTemplate="footer">
    <p-button autofocus routerLink="/" label="Potwierdź" styleClass="p-button-text"></p-button>
  </ng-template>
</p-dialog>
<div class="loginpage">
<div class="center loginComponent"><img class="logo" src="/assets/imagesAndLogos/logo.png" alt="KIDL logo">
  <div class="col-md-4 offset-md-4 mt-5">
    <div class="card">
      <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <h5 class="card-header">Zmiana hasła</h5>
      <div class="card-body">
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col">
              <label style="font-size:14px" for="password">Hasło</label>
              <p-password formControlName="password"
                [inputStyleClass]="submitted && f.password.errors  ? 'form-control mt-2 is-invalid' : 'form-control mt-2'">
              <ng-template pTemplate="header">
                <h6>Wpisz hasło</h6>
              </ng-template>
              <ng-template pTemplate="footer">
                <p-divider></p-divider>
                <p class="p-mt-2">Wymogi:</p>
                <ul class="p-pl-2 p-ml-2 p-mt-0">
                  <li>Przynajmniej jeden mały znak</li>
                  <li>Przynajmniej jeden wielki znak</li>
                  <li>Przynajmniej jedna cyfra</li>
                  <li>Przynajmniej jeden znak specjalny</li>
                  <li>Minimum {{ passwordMinLenght }} znaków</li>
                </ul>
              </ng-template>
            </p-password>
              <div [ngStyle] = "submitted && f.password.errors && {'display':'block'}"  class="invalid-feedback">
                <div *ngIf="f.password.errors?.required">Hasło jest wymagane</div>
                <div *ngIf="f.password.errors?.minlength">Hasło jest za krótkie (minimum {{passwordMinLenght}} znaków)</div>
                <div *ngIf="f.password.errors?.pattern">Hasło nie spełnia wymogów</div>
              </div>
            </div>
            <div class="p-field p-col">
              <label style="font-size:14px" for="password2">Powtórz hasło</label>
              <p-password formControlName="password2"
              [inputStyleClass]="submitted && f.password2.errors  ? 'form-control mt-2 is-invalid' : 'form-control mt-2'">
              <ng-template pTemplate="header">
                <h6>Wpisz hasło</h6>
              </ng-template>
              <ng-template pTemplate="footer">
                <p-divider></p-divider>
                <p class="p-mt-2">Wymogi:</p>
                <ul class="p-pl-2 p-ml-2 p-mt-0">
                  <li>Przynajmniej jeden mały znak</li>
                  <li>Przynajmniej jeden wielki znak</li>
                  <li>Przynajmniej jedna cyfra</li>
                  <li>Przynajmniej jeden znak specjalny</li>
                  <li>Minimum {{ passwordMinLenght }} znaków</li>
                </ul>
              </ng-template>
            </p-password>
              <div [ngStyle] = "submitted && f.password2.errors && {'display':'block'}" class="invalid-feedback">
                <div *ngIf="f.password2.errors?.required">Hasło jest wymagane</div>
                <div *ngIf="f.password2.errors?.minlength">Hasło jest za krótkie (minimum {{passwordMinLenght}} znaków)</div>
                <div *ngIf="f.password2.errors?.pattern">Hasło nie spełnia wymogów</div>
              </div>
            </div>
            <div class="center mt-3">
              <div *ngIf="error" class="alert alert-danger mt-3 mb-2">{{error}}</div>
              <button class="btn btn-primary "><span *ngIf="loading"
                  class="spinner-border spinner-border-sm mr-1"></span>Zatwierdź</button>
              <button class="btn btn-primary  ml-1" routerLink="/">Wróć</button>
            </div>
          </div>
      </div>
    </form>
    </div>
  </div>
</div>
</div>
