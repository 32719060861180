import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { User } from '@app/_models/user';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private userSubject: BehaviorSubject<User>;
  public user: Observable<User>;

  constructor(private router: Router,
    private http: HttpClient
  ) {
    this.userSubject = new BehaviorSubject<User>(null);
    this.user = this.userSubject.asObservable();
  }

  public get userValue(): User {
    return this.userSubject.value;
  }

  login(username: string, password: string) {
    return this.http.post<any>(`${environment.apiUrl}users/authenticate`, { username, password }, { withCredentials: true })
      .pipe(map(user => {
        this.userSubject.next(user);
        this.startRefreshTokenTimer();
        return user;
      }));
  }

  verify(username: string, diagnosticianOriginalId: string, subAccountPattern: string) {
    return this.http.post<any>(`${environment.apiUrl}users/verify`, { username, diagnosticianOriginalId, subAccountPattern }, { withCredentials: true })
      .pipe(map(res => {
        return res;
      }));
  }

  logout() {
    this.http.post<any>(`${environment.apiUrl}users/revoke-token`, {}, { withCredentials: true }).subscribe();
    this.stopRefreshTokenTimer();
    this.userSubject.next(null);
    this.router.navigate(['/login']);
  }

  refreshToken() {
    return this.http.post<any>(`${environment.apiUrl}users/refresh-token`, {}, { withCredentials: true })
      .pipe(map((user) => {
        this.userSubject.next(user);
        this.startRefreshTokenTimer();
        return user;
      }));
  }

  isInRole(roleName: string) {
    if (this.userValue) {
      for (var rola of this.userValue.roles) {
        var rolkanieadminka = this.getEnumKeyByEnumValue(RoleEnum, roleName);
        if (rola.roleName == rolkanieadminka || rola.roleName == RoleEnum.Admin)
          return true;
      }
    }
    return false;
  }
  getEnumKeyByEnumValue(myEnum, enumValue) {
    let keys = Object.keys(myEnum).filter(x => myEnum[x] == enumValue);
    return keys.length > 0 ? keys[0] : null;
  }

  private refreshTokenTimeout: NodeJS.Timeout;

  private startRefreshTokenTimer() {
    // parse json object from base64 encoded jwt token
    const jwtToken = JSON.parse(atob(this.userValue.jwtToken.split('.')[1]));

    // set a timeout to refresh the token a minute before it expires
    const expires = new Date(jwtToken.exp * 1000);
    const timeout = expires.getTime() - Date.now() - (60 * 1000);
    this.refreshTokenTimeout = setTimeout(() => this.refreshToken().subscribe(), timeout);
  }

  private stopRefreshTokenTimer() {
    clearTimeout(this.refreshTokenTimeout);
  }
}
export enum RoleEnum {
  Admin = "Admin",
  Raport = "Raport",
  BookingRead = "Księgowość wgląd",
  BookingWrite = "Księgowość edycja",
  LabRead = "Laboratoria wgląd",
  LabWrite = "Laboratoria edycja",
  DiagnosticianRead = "Diagnosta wgląd",
  DiagnosticianWrite = "Diagnosta edycja",
  PunishedRegister = "Ukarani diagności",
  NotDiagnosticianRead = "Nie diagnosta wgląd",
  NotDiagnosticianWrite = "Nie diagnosta edycja",
  MassLetter = "Pisma masowe",
  Diagnostician = "Diagnosta",
  KIDLEmployee = "Pracownik"
}
