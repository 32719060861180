  <div class="ml-1 mr-1" style="max-height:386px;overflow-y:auto;max-width:698px;">
    <label style="color:black">Wiadomości</label>
    <hr/>
    <div *ngFor="let item of unreadMessages">
      <div class="message-header">
        <b class="title" style="font-family:Halcyon-Semibold">{{item.title.toUpperCase()}}</b>
        <div class="flex relative">
        <b class="title" style="color:black;flex-basis:95%">{{item.content}}</b>
        <i (click)="messageRead(item)" class="absolute" style="cursor:pointer;border-radius:15px;background:#CFCFCF;padding:3px;;right:3%;top:15%;height:1%" class="pi pi-times"></i>
        <div *ngIf="item.attachments.length > 0">
          <div>
            Do wiadomości zostały dołączone załączniki:
            <div *ngFor="let attachment of item.attachments">
              <a (click)="downloadAttachments(attachment)">{{attachment.fileName}}</a>
            </div>
          </div>
        </div>
      </div>
        <b class="title-date" style="color:#ABABAB">{{item.createdDate | date:'dd.MM.YYYY'}}r</b>
        <hr/>
      </div>
    </div>
    <b style="color:black;font-family:Halcyon-Semibold" *ngIf="unreadMessages?.length===0">Brak nowych wiadomości</b>
  </div>
