import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first, finalize } from 'rxjs/operators';
import { AuthenticationService } from '@app/_services/authentication.service';
import { ToastService } from '@app/_services/toast.service';
import { ConfigService } from '@app/_services/config.service';
import { Config } from '@app/_models/config';

@Component({ templateUrl: 'login.component.html' })
export class LoginComponent implements OnInit {
    public config: Config;
    loginForm: FormGroup;
    loading = false;
    submitted = false;
    isUserVerified = true; // domyślnie na true - podczas logowania zostanie sprawdzone
    returnUrl: string;
    error = '';

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private toastService: ToastService,
        private configService: ConfigService
    ) {
        // redirect to home if already logged in
        if (this.authenticationService.userValue) {
            this.router.navigate(['/']);
        }
    }

    ngOnInit() {
        this.config = this.configService.getConfig();

        this.loginForm = this.formBuilder.group({
            username: ['', Validators.required],
            password: ['', Validators.required]
        });

        if (this.config.loginEnabled == false) {
            this.loginForm.disable();
        }

        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    }

    get f() { return this.loginForm.controls; }
    onSubmit() {
        this.submitted = true;
        this.error = '';

        if (this.loginForm.invalid) return;

        this.loading = true;
        this.authenticationService.login(this.f.username.value, this.f.password.value)
            .pipe(
                first(),
                finalize(() => this.loading = false))
            .subscribe({
                next: () => this.router.navigate([this.returnUrl]),
                error: (error: any) => {
                    if (error.name == "EmptyError") return;
                    switch (error) {
                        case "Unknown Error": break;
                        case "Użytkownik wymaga weryfikacji.": this.isUserVerified = false; // Uwaga! przejdzie dalej ze względu na brak "break"
                        default: this.error = error.message || error;
                    }
                }
            });
    }

    handleUserVerification(value: boolean) {
        this.isUserVerified = value;
        this.toastService.addSuccess("Użytkownik został zweryfikowany.", 3);
        this.error = '';
    }
}
