import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '@app/_services/authentication.service';
import { SystemMessageService } from '@app/_services/system-message.service';
import { ToastService } from '@app/_services/toast.service';
import { environment } from '@environments/environment';
import { FileUpload } from 'primeng/fileupload';

interface Document {
  id: number;
}

interface Application {
  title: string,
  type: string,
  defaultMessage?: string,
  isApplication?: boolean,
  requiresAttachment?: boolean
}

const ApplicationTitleDict = {
  ChangeDataRequest: "Wniosek o aktualizację danych - art. 160",
  ChangeAddressDataRequest: "Wniosek o zmianę danych adresowych", // Tymczasowo znika z listy wyboru na życzenie Aleksandry Dynerowicz
  ChangePhotoRequest: "Wniosek o zmianę zdjęcia", // Tymczasowo znika z listy wyboru na życzenie Aleksandry Dynerowicz
  RemarksToList: "Uwagi do Nr PWZDL/Wpisu na listę",
  ExclusionFromContributions: "Wyłączenia ze składek",
  Payments: "Wpłaty",
  Others: "Inne"
};

const ApplicationTypeDict = {
  diag: "diag",
  pay: "pay",
  other: "other"
};

const ApplicationTypes = {
  ChangeDataRequest: {
    title: ApplicationTitleDict.ChangeDataRequest,
    defaultMessage: "Dzień dobry, przesyłam plik z aktualizacją danych. Pozdrawiam",
    type: ApplicationTypeDict.diag,
    isApplication: true,
    requiresAttachment: true
  },
  // Poniższe tymczasowo znikają z listy wyboru na życzenie Aleksandry Dynerowicz
  // ChangeAddressDataRequest: {
  //   title: ApplicationTitleDict.ChangeAddressDataRequest,
  //   type: ApplicationTypeDict.diag,
  //   isApplication: true
  // },
  // ChangePhotoRequest: {
  //   title: ApplicationTitleDict.ChangePhotoRequest,
  //   defaultMessage: "Your default message here",
  //   type: ApplicationTypeDict.diag,
  //   isApplication: true
  // },
  // RemarksToList: {
  //   title: ApplicationTitleDict.RemarksToList,
  //   type: ApplicationTypeDict.diag
  // },
  // ExclusionFromContributions: {
  //   title: ApplicationTitleDict.ExclusionFromContributions,
  //   type: ApplicationTypeDict.pay
  // },
  // Payments: {
  //   title: ApplicationTitleDict.Payments,
  //   type: ApplicationTypeDict.pay
  // },
  // Others: {
  //   title: ApplicationTitleDict.Others,
  //   type: ApplicationTypeDict.other
  // }
};

@Component({
  selector: 'app-diag-applications',
  templateUrl: './diag-applications.component.html',
  styleUrls: ['./diag-applications.component.less']
})

export class DiagApplicationsComponent implements OnInit {
  @ViewChild("attachmentUpload") fileUpload: FileUpload;

  form: FormGroup;
  loading: boolean = false;
  submitted: boolean = false;
  displayCompleteDialog: boolean = false;
  displayErrorDialog: boolean = false;
  private docs: Document[];
  endpointUrl: string = `${environment.apiUrl}documents/save-message-attachments`;
  uploadedFiles: File[] = [];
  ApplicationTypes: Application[] = [
    ApplicationTypes.ChangeDataRequest,
    // Poniższe tymczasowo znikają z listy wyboru na życzenie Aleksandry Dynerowicz
    // ApplicationTitles.ChangeAddressDataRequest,
    // ApplicationTitles.ChangePhotoRequest,
    // ApplicationTypes.RemarksToList,
    // ApplicationTypes.ExclusionFromContributions,
    // ApplicationTypes.Payments,
    // ApplicationTypes.Others
  ]

  constructor(
    private formBuilder: FormBuilder,
    private service: SystemMessageService,
    private toastService: ToastService,
    private http: HttpClient,
    private authService: AuthenticationService) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      title: ['', [Validators.required]],
      content: ['', [Validators.required]]
    });
  }

  get f() { return this.form.controls; }

  uploadFiles(event: {files: File[]}) {
    this.uploadedFiles = [...event.files];
  }

  onApplicationTitleChange(event: any) {
    this.resetFormBody();
    this.setDefaultApplicationMessage();
  }

  // Ustawia domyślną treść wiadomości jeśli istnieje
  private setDefaultApplicationMessage() {
    if (this.f.title.valid) {
      for (let key in ApplicationTypes) {
        if (ApplicationTypes[key].title === this.f.title.value.title && ApplicationTypes[key].defaultMessage) {
          this.f.content.setValue(ApplicationTypes[key].defaultMessage); break;
        }
      }
    }
  }

  async send() {
    this.submitted = true;

    if (!this.form.valid)
      return;

    if (this.f.title.value.requiresAttachment && this.uploadedFiles.length === 0) {
      this.toastService.addError("Nie można wysłać wniosku o zmianie danych. Konieczne jest załączenie min. jednego dokumentu w rozszerzeniu doc, docx, xls, xlsx lub pdf", 6);
      return;
    }

    if (this.uploadedFiles.length > 0) {
      try {
        await this.uploadDocuments();
      } catch {
        this.toastService.addError("Podczas wysyłania wniosku wystąpił błąd.", 6);
        return;
      }
    }

    let body = {
      title: `Wpłynął "${this.f.title.value.title}" dla diagnosty nr wpisu na listę ${this.authService.userValue.diagnosticianOrgId}`,
      content: this.f.content.value,
      isApplication: this.f.title.value.isApplication,
      docIds: this.docs?.map(x => x.id)
    };

    await this.service.sendMessage(body, this.f.title.value.type);
    this.resetForm();
    this.displayCompleteDialog = true;
  }

  async uploadDocuments() {
    this.toastService.putInMask();
    let formData: FormData = new FormData();

    this.uploadedFiles.forEach((file, i) => {
      formData.append(`uploadedFile${i}`, file, file.name);
    });

    let params = new HttpParams().set('title', this.f.title.value.title);
    this.docs = <any[]>await this.http.post(this.endpointUrl, formData, { params: params }).toPromise();
    this.toastService.putOutMask();
  }

  resetForm() {
    this.f.title.setValue(null);
    this.f.title.markAsPristine();
    this.resetFormBody();
  }

  // Resetuje wszystkie elementy formularza po zmianie tytułu
  private resetFormBody() {
    this.f.content.setValue(null);
    this.f.content.markAsPristine();
    this.fileUpload.clear();
    this.uploadedFiles = [];
    this.submitted = false;
    this.toastService.putOutMask();
  }

  onSelect(event: {files: File[], currentFiles: File[]}) {
    let y = event.files[0];
    let item = event.currentFiles.find(x => x.name === y.name && x.size === y.size && x.lastModified === y.lastModified);
    if (event.currentFiles.length === 0 || !item)
      this.displayErrorDialog = true;
  }

  add() {
    let input = <HTMLInputElement>document.querySelector('input[type=file]');
    input.click();
  }

  deleteAttach(attachment: any) {
    this.fileUpload.files = this.fileUpload.files.filter(x => x != attachment);
    this.uploadedFiles = this.uploadedFiles.filter(x => x != attachment);
    this.fileUpload.uploadedFileCount = this.fileUpload.uploadedFileCount - 1;
  }
}
