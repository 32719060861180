<div class="loginpage">
  <div class="loginComponent center">
    <img class = "logo" src="/assets/imagesAndLogos/logo.png" alt="KIDL logo">
<p-steps #steps [activeIndex]="activeIndex" [model]="items"></p-steps>
<p-dialog [closable]="false" header="Rejestracja zakończona" [(visible)]="displayCompleteDialog"
  [breakpoints]="{'960px': '75vw'}" [style]="{width: '50vw'}" [baseZIndex]="10000" [draggable]="false"
  [resizable]="false">
  <p>{{needsActivation ? dialogContentUnactiv : dialogContent}}</p>
  <ng-template pTemplate="footer">
    <p-button type="button" autofocus routerLink="/" label="Potwierdź" styleClass="p-button-text"></p-button>
  </ng-template>
</p-dialog>
<div class="col-md-4 offset-md-4 mt-5">
  <div class="card">
    <ng-container *ngIf="activeIndex===0">
      <h5 class="card-header">Wstępna weryfikacja</h5>
      <div #step1 class="card-body">
        <form [formGroup]="form">
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col">
              <label style="font-size:14px" for="nrPWZDL">Nr PWZDL</label>
              <input type="text" maxlength="7" formControlName="nrPWZDL" pKeyFilter="pint" class="form-control mt-2"
                [ngClass]="{ 'is-invalid': submitted && f.nrPWZDL.errors }" />
              <div *ngIf="submitted && f.nrPWZDL.errors" class="invalid-feedback">
                <div *ngIf="f.nrPWZDL.errors.required">Numer PWZDL jest wymagany</div>
              </div>
            </div>
            <div class="p-field p-col">
              <label style="font-size:14px" for="diagnosticianOrginalId">Nr wpisu</label>
              <input type="text" maxlength="7" formControlName="diagnosticianOrginalId" pKeyFilter="pint" class="form-control mt-2"
                [ngClass]="{ 'is-invalid': submitted && f.diagnosticianOrginalId.errors }" />
              <div *ngIf="submitted && f.diagnosticianOrginalId.errors" class="invalid-feedback">
                <div *ngIf="f.diagnosticianOrginalId.errors.required">Numer wpisu jest wymagany</div>
              </div>
            </div>
            <div class="p-field p-col">
              <label style="font-size:14px" for="pesel">PESEL</label>
              <input type="text" maxlength="11" minlength="11" pKeyFilter="pint" maxlength="11" pInputText formControlName="pesel" class="form-control mt-2"
                [ngClass]="{ 'is-invalid': submitted && f.pesel.errors }" />
              <div *ngIf="submitted && f.pesel.errors" class="invalid-feedback">
                <div *ngIf="f.pesel.errors.required">Numer PESEL jest wymagany</div>
                <div *ngIf="f.pesel.errors.minlength || f.pesel.errors.maxlength">
                  Numer PESEL musi mieć 11 znaków
                </div>
                <div *ngIf="f.pesel.errors && !f.pesel.errors.required">
                  Numer PESEL nie jest poprawny
                </div>
              </div>
            </div>
            <div class="form-group center mt-3">
              <div *ngIf="error" class="alert alert-danger mt-3 mb-2">{{error}}</div>
              <button type="button" class="btn btn-primary ml-1 mr-1" (click)="sendData(0)"><span *ngIf="loading"
                  class="spinner-border spinner-border-sm mr-1"></span>Zatwierdź</button>
              <button type="button" class="btn btn-primary ml-1 mr-1" routerLink='/'>Wróć</button>
            </div>
          </div>
        </form>
      </div>
    </ng-container>
    <ng-container *ngIf="activeIndex===1">
      <h5 class="card-header">Potwierdzenie danych</h5>
      <div #step1 class="card-body">
        <form [formGroup]="verifyAccountForm">
          <div class="form-group">
              <label style="font-size:14px" for="subAccountPatternPrefix">Podaj pierwsze 2 znaki swojego subkonta
                  bankowego</label>
              <p-inputMask type="text" formControlName="subAccountPatternPrefix" class="mt-1 form-control"
                  [ngClass]="{ 'is-invalid': submitted && verifyAccountForm.controls.subAccountPatternPrefix.errors }" 
                  mask="99"
                  placeholder="99"></p-inputMask>
              <div *ngIf="submitted && vf.subAccountPatternPrefix.errors" class="invalid-feedback">
                <div *ngIf="vf.subAccountPatternPrefix.errors?.required">
                  Pole jest wymagane!
                </div>
                <div *ngIf="vf.subAccountPatternPrefix.errors?.pattern">
                  Dozwolone wyłącznie cyfry, długość musi wynosić 2 znaków!
                </div>
              </div>
              <label style="font-size:14px" for="subAccountPatternSufix">Podaj ostatnie 6 znaków swojego subkonta
                  bankowego</label>
              <p-inputMask type="text" formControlName="subAccountPatternSufix" class="mt-1 form-control"
                  [ngClass]="{ 'is-invalid': submitted && verifyAccountForm.controls.subAccountPatternSufix.errors }" 
                  mask="999999"
                  placeholder="999999"></p-inputMask>
              <div *ngIf="submitted && vf.subAccountPatternSufix.errors" class="invalid-feedback">
                <div *ngIf="vf.subAccountPatternSufix.errors?.required">
                  Pole jest wymagane!
                </div>
                <div *ngIf="vf.subAccountPatternSufix.errors?.pattern">
                  Dozwolone wyłącznie cyfry, długość musi wynosić 6 znaków!
                </div>
              </div>
          </div>
          <div *ngIf="error" class="alert alert-danger mt-3 mb-2">{{error}}</div>
          <button type="button" class="btn btn-primary ml-1 mr-1" (click)="sendData(1)"><span *ngIf="loading"
            class="spinner-border spinner-border-sm mr-1"></span>Zweryfikuj konto</button>
          <button type="button" class="btn btn-primary  ml-1 mr-1" (click)="moveToStep(0)">Wróć</button>
      </form>
      </div>
    </ng-container>
    <ng-container *ngIf="activeIndex===2">
      <h5 class="card-header">Zatwierdzenie danych</h5>
      <div #step1 class="card-body">
        <div class="p-fluid p-formgrid p-grid">
          <form [formGroup]="formFinal">
            <div class="p-field p-col">
              <label style="font-size:14px" for="email">E-mail</label>
              <input type="text" formControlName="email" class="form-control mt-2"
                [ngClass]="{ 'is-invalid': submitted && ff.email.errors }" />
              <div *ngIf="submitted && ff.email.errors" class="invalid-feedback">
                <div *ngIf="ff.email.errors.required">Email jest wymagany</div>
                <div *ngIf="ff.email.errors.email">
                  Nieprawidłowy adres e-mail
                </div>
              </div>
            </div>
            <div class="p-field p-col">
              <label style="font-size:14px" for="email2">Powtórz E-mail</label>
              <input type="text" formControlName="email2" class="form-control mt-2"
                [ngClass]="{ 'is-invalid': submitted && ff.email2.errors }" />
              <div *ngIf="submitted && ff.email2.errors" class="invalid-feedback">
                <div *ngIf="ff.email2.errors.required">Email jest wymagany</div>
                <div *ngIf="ff.email2.errors.email">
                  Nieprawidłowy adres e-mail
                </div>
              </div>
            </div>
            <div class="p-field p-col">
              <label style="font-size:14px" for="password">Hasło</label>
              <p-password formControlName="password"
                [inputStyleClass]="submitted && ff.password.errors  ? 'form-control mt-2 is-invalid' : 'form-control mt-2'">
                <ng-template pTemplate="header">
                  <h6>Wpisz hasło</h6>
                </ng-template>
                <ng-template pTemplate="footer">
                  <p-divider></p-divider>
                  <p class="p-mt-2">Wymogi:</p>
                  <ul class="p-pl-2 p-ml-2 p-mt-0">
                    <li>Przynajmniej jeden mały znak</li>
                    <li>Przynajmniej jeden wielki znak</li>
                    <li>Przynajmniej jedna cyfra</li>
                    <li>Przynajmniej jeden znak specjalny</li>
                    <li>Minimum {{ passwordMinLenght }} znaków</li>
                  </ul>
                </ng-template>
              </p-password>
              <div [ngStyle]="submitted && ff.password.errors && {'display':'block'}" class="invalid-feedback">
                <div *ngIf="ff.password.errors?.required">Hasło jest wymagane</div>
                <div *ngIf="ff.password.errors?.minlength">Hasło jest za krótkie (minimum {{passwordMinLenght}} znaków)
                </div>
                <div *ngIf="ff.password.errors?.pattern">Hasło nie spełnia wymogów</div>
              </div>
            </div>
            <div class="p-field p-col">
              <label style="font-size:14px" for="password2">Powtórz hasło</label>
              <p-password formControlName="password2"
                [inputStyleClass]="submitted && ff.password2.errors  ? 'form-control mt-2 is-invalid' : 'form-control mt-2'">
                <ng-template pTemplate="header">
                  <h6>Wpisz hasło</h6>
                </ng-template>
                <ng-template pTemplate="footer">
                  <p-divider></p-divider>
                  <p class="p-mt-2">Wymogi:</p>
                  <ul class="p-pl-2 p-ml-2 p-mt-0">
                    <li>Przynajmniej jeden mały znak</li>
                    <li>Przynajmniej jeden wielki znak</li>
                    <li>Przynajmniej jedna cyfra</li>
                    <li>Przynajmniej jeden znak specjalny</li>
                    <li>Minimum {{ passwordMinLenght }} znaków</li>
                  </ul>
                </ng-template>
              </p-password>
              <div [ngStyle]="submitted && ff.password2.errors && {'display':'block'}" class="invalid-feedback">
                <div *ngIf="ff.password2.errors?.required">Hasło jest wymagane</div>
                <div *ngIf="ff.password2.errors?.minlength">Hasło jest za krótkie (minimum {{passwordMinLenght}} znaków)
                </div>
                <div *ngIf="ff.password2.errors?.pattern">Hasło nie spełnia wymogów</div>
              </div>
            </div>
          </form>
          <div style="text-align:left">
          <div class="d-inline-flex p-field p-col mt-1">
            <p-checkbox id="agreement" class="agreement" [(ngModel)]="agreement" [binary]="true">
            </p-checkbox>
            <label for="agreement">
              Oświadczam, że zapoznałam/em się i akceptuję <a target="_parent" href="assets\pdfs\Regulamin.pdf" download>Regulamin korzystania z Systemu
              bazodanowego służącego do prowadzenia ewidencji danych i obsługi diagnostów laboratoryjnych oraz obsługi laboratoriów prowadzonego dla diagnostów
              laboratoryjnych przez Krajową Izbę Diagnostów Laboratoryjnych w Warszawie</a> (KIDL).
            </label>
          </div>
          <div class="d-inline-flex p-field p-col mt-1">
            <p-checkbox id="agreement2" class="agreement" [(ngModel)]="agreement2" [binary]="true">
            </p-checkbox>
            <label for="agreement2">
                Oświadczam, że zapoznałam/em się z <a target="_parent" href="assets\pdfs\Polityka Prywatności.pdf" download>Polityką Prywatności</a> i <a target="_parent" href="assets\pdfs\Klauzula Informacyjna.pdf" download>Klauzulą Informacyjną dotyczącymi przetwarzania danych osobowych</a> Krajową Izbę Diagnostów
                Laboratoryjnych w Warszawie i jej organy oraz wyrażam zgodę na ich powierzenie, co jest konieczne do wykonywania przez KIDL i jej organy ustawowo
                powierzonych zadań w interesie publicznym, w szczególności przez ustawę z dnia 27 lipca 2001 r. o diagnostyce laboratoryjnej
                (tj. Dz. U. z 2022 r., poz. 134 z późn. zm.). Przyjęłam/jąłem także do wiadomości, iż ww. dokumenty zamieszczone są również na stronie internetowej
                www.kidl. org.pl w kategorii Inne zakładce „RODO”.
            </label>
          </div>
          <div class="d-inline-flex p-field p-col mt-1">
            <p-checkbox id="agreement3" class="agreement" [(ngModel)]="agreement3" [binary]="true">
            </p-checkbox>
            <label for="agreement3">
              Wyrażam zgodę na komunikację elektroniczną przez KIDL i jej organy za pośrednictwem Systemu bazodanowego służącego do prowadzenia ewidencji danych i
              obsługi diagnostów laboratoryjnych oraz obsługi laboratoriów prowadzonego dla diagnostów laboratoryjnych przez Krajową Izbę Diagnostów Laboratoryjnych w
              Warszawie w ramach jego funkcjonalności.
            </label>
          </div>
        </div>
          <div class="form-group center mt-3">
            <div *ngIf="error" class="alert alert-danger mt-3 mb-2">{{error}}</div>
            <button type="button" class="btn btn-primary  ml-1 mr-1" (click)="sendData(2)"><span *ngIf="loading"
                class="spinner-border spinner-border-sm mr-1"></span>Zatwierdź</button>
            <button type="button" class="btn btn-primary  ml-1 mr-1" (click)="moveToStep(0)">Wróć</button>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
</div>
</div>
