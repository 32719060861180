import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { User } from '@app/_models/user';
import { AuthenticationService } from '@app/_services/authentication.service';
import { ToastService } from '@app/_services/toast.service';
import { UserService } from '@app/_services/user.service';
import { environment } from '@environments/environment';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html'
})
export class MyProfileComponent implements OnInit {
  form: FormGroup;
  loading: boolean = false;
  submitted: boolean = false;
  agreement: boolean = false;
  displayCompleteDialog: boolean = false;
  private readonly user: User;
  readonly passwordMinLenght: number = environment.passwordMinLenght;

  constructor(
    private confirmationService: ConfirmationService,
    private formBuilder: FormBuilder,
    private service: UserService,
    private authService: AuthenticationService,
    private toastService: ToastService) {
    this.user = this.authService.userValue;

  }

  get f() { return this.form.controls; }

  ngOnInit(): void {
    this.agreement = this.user.acceptedElectronicalCommunication;

    this.form = this.formBuilder.group({
      oldPassword: ['', [Validators.required]],
      password: ['', [Validators.pattern(environment.passwordRegex),Validators.required, Validators.minLength(environment.passwordMinLenght),Validators.pattern("^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$")]],
      password2: ['', [Validators.pattern(environment.passwordRegex),Validators.required, Validators.minLength(environment.passwordMinLenght),Validators.pattern("^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$"),]]
    });
  }

  sendData(): void {
    this.submitted = true;

    if (!this.f.oldPassword.valid || !this.f.password.valid || !this.f.password2.valid) {
      return;
    }
    if (this.f.password.value !== this.f.password2.value) {
      this.toastService.addWarning("Hasła nie są identyczne.", 6);
      return;
    }

    let body = {
      userId: this.user.id,
      oldPassword: this.f.oldPassword.value,
      newPassword: this.f.password.value
    }

    this.confirmationService.confirm({
      message: '<div class="text-center bold">Potwierdź ostatecznie zmianę swojego hasła.<br/> <div class="blue mt-6">Czy chcesz zmienić aktualne hasło na nowe?</div></div>',
      accept: () => {
        this.service.changeUserPassword(body).pipe().subscribe({
          next: () => {
            this.displayCompleteDialog = true;
            this.f.oldPassword.setValue(null);
            this.f.password.setValue(null);
            this.f.password2.setValue(null);
            this.f.oldPassword.markAsPristine();
            this.f.password.markAsPristine();
            this.f.password2.markAsPristine();
          }
        });
      }
    });
    this.submitted = false;
  }
}
