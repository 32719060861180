import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  private maskSubject: BehaviorSubject<boolean>;
  public mask: Observable<boolean>;

  constructor(private messageService: MessageService) {
    this.maskSubject = new BehaviorSubject<boolean>(false);
    this.mask = this.maskSubject.asObservable();
  }
  addSuccess(description: string, time: number) {
    this.messageService.add({
      severity: 'success',
      summary: 'Powiadomienie aplikacji',
      detail: description,
      life: time * 1000
    });
    this.putOutMask();
  }
  addWarning(description: string, time: number) {
    this.messageService.add({
      severity: 'warn',
      summary: 'Błąd walidacji',
      detail: description,
      life: time * 1000
    });
    this.putOutMask();
  }
  addError(description: string, time: number) {
    this.messageService.add({
      severity: 'error',
      summary: 'Błąd aplikacji',
      detail: description,
      life: time * 1000
    });
    this.putOutMask();
  }
  addInfo(description: string, time: number) {
    this.messageService.add({
      severity: 'info',
      summary: 'Informacja',
      detail: description,
      life: time * 1000
    });
  }
  putInMask() {
    this.maskSubject.next(true);
  }
  putOutMask() {
    this.maskSubject.next(false);
  }
}

