<p-toast [baseZIndex]="1000000"
  [breakpoints]="{'920px': {width: '100%', right: '0', left: '0'}}"></p-toast>

<!-- wiadomość techniczna na górze layout -->
<p-message *ngIf="config?.technicalMessage?.message"
  [severity]="config.technicalMessage.level || 'info'"
  styleClass="w-full"
  [text]="config.technicalMessage.message"
  [closable]="false"></p-message>

<p-overlayPanel #op>
  <ng-template pTemplate>
    <app-system-message (onLastRead)="op.hide()"></app-system-message>
  </ng-template>
</p-overlayPanel>

<p-overlayPanel #op2>
  <ng-template pTemplate>
    <div style="min-width:150px">
      <span class="grey">{{diagnosticianName}}</span>
      <div class="saldomobile tableheader"
        style="padding-top:5px; opacity: 0"
        *ngIf="user?.balance !== null && user?.balance !== undefined">
        SALDO<span style="-moz-border-radius: 25px;
    border-radius: 25px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 5px;
    padding-bottom: 1px;"
          class="tableheader ml-2"
          [ngStyle]="user?.balance<0 ? {'background':'#FFCCCC','color':'#FA1A1A'} : {'background':'#C1FFBB','color':'#005706'}">{{user.balance.toFixed(2)}}
          PLN</span>
      </div>
      <hr />
      <label class="profileLabel"
        routerLink="/myProfile">Zmień hasło</label>
      <br />
      <br />
      <label class="profileLabel"
        (click)="open('assets/pdfs/Regulamin.pdf')">Regulamin</label>
      <br />
      <br />
      <label class="profileLabel"
        (click)="open('assets/pdfs/Polityka Prywatności.pdf')">Polityka Prywatności</label>
      <br />
      <br />
      <label class="profileLabel"
        (click)="open('assets/pdfs/Klauzula Informacyjna.pdf')">Klauzula Informacyjna</label>
      <br />
      <br />
      <label class="profileLabel"
        (click)="logout()">Wyloguj</label>
    </div>
  </ng-template>
</p-overlayPanel>

<p-confirmDialog [defaultFocus]="'reject'"
  [rejectButtonStyleClass]="'revertbutton'"
  [rejectLabel]="'Nie, powrót'"
  [closable]="false"
  icon="pi pi-exclamation-triangle"></p-confirmDialog>

<p-progressSpinner *ngIf="display"
  #mask></p-progressSpinner>


<div class="d-flex">
  <ng-container *ngIf="user">
    <div class="collapse navbar-collapse"
      id="navbarSupportedContent">
      <h6 style="color:white; font-family: Halcyon-Semibold;font-size:20px"
        class="center m-3">Aplikacja Diagnostów
        Laboratoryjnych</h6>
      <hr style="margin-left:30px;color:white;" />
      <ul class="navbar-nav mt-5">
        <li class="nav-item"
          [ngClass]="{'active':url=='/'}">
          <div class="inline-flex ">
            <img class="ml-4 nav-icon"
              src="/assets/ikony_menu/tiles-icon.svg">
            <a class="nav-link"
              data-bs-toggle="collapse"
              data-bs-target=".navbar-collapse.show"
              routerLink="/">Strona
              Główna</a>
          </div>
        </li>
        <!-- Ukryto na życzenie klienta -->
        <!-- <li class="nav-item" [ngClass]="{'active':url=='/diagPersonalData'}">
        <div class="inline-flex ">
          <img class="ml-4 nav-icon" src="/assets/ikony_menu/user-icon.svg">

          <a class="nav-link" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show"
            routerLink="/diagPersonalData">Moje dane</a>
        </div>
      </li> -->
        <li class="nav-item"
          [ngClass]="{'active':url=='/diagDocuments'}">
          <div class="inline-flex ">
            <img class="ml-4 nav-icon"
              src="/assets/ikony_menu/duplicate-icon.svg">
            <a class="nav-link"
              data-bs-toggle="collapse"
              data-bs-target=".navbar-collapse.show"
              routerLink="/diagDocuments">Moje dokumenty</a>
          </div>
        </li>
        <!-- <li class="nav-item" [ngClass]="{'active':url=='/diagFinances'}">
        <div class="inline-flex ">
          <img class="ml-4 nav-icon" src="/assets/ikony_menu/ic_credit_card_48px.svg">

          <a class="nav-link" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show"
            routerLink="/diagFinances">Moje rozliczenia</a>
        </div>
      </li> -->
        <li class="nav-item"
          [ngClass]="{'active':url=='/diagApplications'}">
          <div class="inline-flex ">
            <img class="ml-4 nav-icon"
              src="/assets/ikony_menu/request-quote-svgrepo-com.svg">

            <a class="nav-link"
              data-bs-toggle="collapse"
              data-bs-target=".navbar-collapse.show"
              routerLink="/diagApplications">Wnioski</a>
          </div>
        </li>
      </ul>
      <label class="nav-return"
        (click)="navigate('https://kidl.org.pl/')">🠔 Powrót do strony Izby</label>
    </div>
  </ng-container>

  <div class="container">
    <a class="close-navbar-toggler collapsed"
      data-bs-toggle="collapse"
      data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation">
    </a>
    <nav *ngIf="user"
      class="navbar navbar-expand-* navbar-dark bg-dark sticky-top">
      <button id="nav-but"
        style="background-color:white !important;"
        pButton
        class="ml-2"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="saldo tableheader text-end ml-auto"
        style="padding-top:5px; opacity: 0"
        *ngIf="user.balance !== null && user.balance !== undefined">
        SALDO<span class="tableheader saldo ml-2"
          [ngStyle]="user.balance<0 ? {'background':'#FFCCCC','color':'#FA1A1A'} : {'background':'#C1FFBB','color':'#005706'}">{{user.balance.toFixed(2)}}
          PLN</span>
      </div>
      <div class="text-center"
        [ngClass]="{'ml-2': user.balance !== null && user.balance !== undefined, 'ml-auto': !(user.balance !== null && user.balance !== undefined)}">
        <img class="mr-3"
          [src]="messages ? '/assets/ikony pozostale/wiadomość_nowa.svg' : '/assets/ikony pozostale/wiadomosci.svg'"
          (click)="op.toggle($event)">
        <img class="mr-3 ml-2"
          style="width:43.25px;height:43.25px;border-radius:50%"
          *ngIf="diagnosticianPhoto"
          [src]="diagnosticianPhoto | safe"
          alt="Zdjęcie diagnosty"
          (click)="op2.toggle($event)">
        <img class="mr-3 ml-2"
          *ngIf="!diagnosticianPhoto"
          src="/assets/ikony pozostale/empty_zdjecie.svg"
          alt="Brak zdjęcia"
          (click)="op2.toggle($event)">
      </div>
    </nav>
    <div style="background-color:#F6F6F6;">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>