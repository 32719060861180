<div class="page backgroundlock">
  <h5 class="segmentheader">ZMIEŃ HASŁO</h5>
  <hr />
  <p-dialog [closable]="false" header="Gotowe." [(visible)]="displayCompleteDialog"
  [breakpoints]="{'960px': '75vw'}" [style]="{width: '50vw'}" [baseZIndex]="10000" [draggable]="false"
  [resizable]="false">
  <span class="blue">Twoje hasło zostało zmienione.</span>
  <ng-template pTemplate="footer">
    <p-button autofocus (click)="displayCompleteDialog = false" label="OK" styleClass="p-button-text"></p-button>
  </ng-template>
</p-dialog>
  <div class="grid mt-2">
    <div class="md:col-4 col">
      <div [formGroup]="form" class="shadow-5 p-3 whitearea">
        <div class="col">
          <label class="tableheader tabletitle">Zmiana hasła</label>
        </div>
        <div class="col">
          <span class="p-float-label mt-3">
            <p-password id="float-p2" [toggleMask]="true" [feedback]="false" formControlName="oldPassword"
              styleClass="p-password p-component p-inputwrapper p-input-icon-right"
              [ngClass]="{ 'ng-invalid ng-dirty': submitted && form.controls.password2.errors }"
              [inputStyleClass]="'form-control'">
            </p-password>
            <label for="float-p2">Aktualne hasło</label>
          </span>
          <div [ngStyle]="submitted && form.controls.oldPassword.errors && {'display':'block'}"
          class="invalid-feedback">
          <div *ngIf="form.controls.oldPassword.errors?.required">Aktualne hasło jest wymagane</div>
        </div>
        </div>
        <div class=" col">
          <span class="p-float-label mt-3">
            <p-password id="float-p1" formControlName="password"
              styleClass="p-password p-component p-inputwrapper p-input-icon-right"
              [ngClass]="{ 'ng-invalid ng-dirty': submitted && form.controls.password2.errors }"
              [inputStyleClass]="'form-control'">
              <ng-template pTemplate="header">
                <h6>Wpisz hasło</h6>
              </ng-template>
              <ng-template pTemplate="footer">
                <p-divider></p-divider>
                <p class="p-mt-2">Wymogi:</p>
                <ul class="p-pl-2 p-ml-2 p-mt-0">
                  <li>Przynajmniej jeden mały znak</li>
                  <li>Przynajmniej jeden wielki znak</li>
                  <li>Przynajmniej jedna cyfra</li>
                  <li>Przynajmniej jeden znak specjalny</li>
                  <li>Minimum {{ passwordMinLenght }} znaków</li>
                </ul>
              </ng-template>
            </p-password>
            <label for="float-p1">Nowe hasło</label>
          </span>
          <div [ngStyle]="submitted && form.controls.password.errors && {'display':'block'}" class="invalid-feedback">
            <div *ngIf="form.controls.password.errors?.required">Hasło jest wymagane</div>
            <div *ngIf="form.controls.password.errors?.minlength">Hasło jest za krótkie (minimum {{passwordMinLenght}}
              znaków)
            </div>
            <div *ngIf="form.controls.password.errors?.pattern">Hasło nie spełnia wymogów</div>
          </div>
        </div>
        <div class="col">
          <span class="p-float-label mt-3">
            <p-password id="float-p2" formControlName="password2"
              styleClass="p-password p-component p-inputwrapper p-input-icon-right"
              [ngClass]="{ 'ng-invalid ng-dirty': submitted && form.controls.password2.errors }"
              [inputStyleClass]="'form-control'">
              <ng-template pTemplate="header">
                <h6>Wpisz hasło</h6>
              </ng-template>
              <ng-template pTemplate="footer">
                <p-divider></p-divider>
                <p class="p-mt-2">Wymogi:</p>
                <ul class="p-pl-2 p-ml-2 p-mt-0">
                  <li>Przynajmniej jeden mały znak</li>
                  <li>Przynajmniej jeden wielki znak</li>
                  <li>Przynajmniej jedna cyfra</li>
                  <li>Przynajmniej jeden znak specjalny</li>
                  <li>Minimum {{ passwordMinLenght }} znaków</li>
                </ul>
              </ng-template>
            </p-password>
            <label for="float-p2">Powtórz hasło</label>
          </span>
          <div [ngStyle]="submitted && form.controls.password2.errors && {'display':'block'}"
              class="invalid-feedback">
              <div *ngIf="form.controls.password2.errors?.required">Hasło jest wymagane</div>
              <div *ngIf="form.controls.password2.errors?.minlength">Hasło jest za krótkie (minimum
                {{passwordMinLenght}} znaków)
              </div>
              <div *ngIf="form.controls.password2.errors?.pattern">Hasło nie spełnia wymogów</div>
            </div>
        </div>
        <div class="col text-right">
          <button class="mt-5" pButton [label]="'Zapisz zmiany'" (click)="sendData()"><span *ngIf="loading"
              class="spinner-border spinner-border-sm mr-1"></span></button>
        </div>
      </div>
    </div>
  </div>
</div>
