<div class="page">
  <h5 class="segmentheader">MOJE ROZLICZENIA</h5>
  <hr/>
  <div class="grid">
    <div class="col-12">
      <div class="shadow-5 whitearea">
          <app-ngrid [title]="'Należności'" [lazy]="false" [selectMode]="'none'"
            [apiMethod]="'diag-receivables/get-all-for-diag'" [cols]="[
            { field: 'month', header: 'Miesiąc', columnType: 'month' },
            { field: 'year', header: 'Rok' },
            { field: 'receivableInitial', header: 'Należność składki', columnType: 'money' },
            { field: 'receivableCurrent', header: 'Saldo należności', columnType: 'money' },
            { field: 'createdDate', header: 'Data utworzenia', columnType: 'date' }
            ]" [additionalData]="additionalData">
          </app-ngrid>
        </div>
        </div>
        <div class="col-12">
          <div class="shadow-5 whitearea">
          <app-ngrid #payGrid [title]="'Wpłaty'" [lazy]="false" [selectMode]="'none'" [apiMethod]="'diag-payments/get-all-for-diag'"
            [cols]="[
            { field: 'date', header: 'Data wpłaty', columnType: 'date' },
            { field: 'amount', header: 'Kwota wpłaty składki', columnType: 'money' },
            { field: 'amountCleared', header: 'Kwota rozliczona składki', columnType: 'money' },
            { field: 'amountNotCleared', header: 'Kwota nierozliczona składki', columnType: 'money' },
            ]" [additionalData]="additionalData">
          </app-ngrid>
        </div>
        </div>
        <div class="col-12">
          <div class="shadow-5 whitearea">
          <app-ngrid #demGrid [title]="'Wezwania do zapłaty'" [selectMode]="'none'" [lazy]="false" [apiMethod]="'documents/get-demands'"
            [cols]="[
            { field: 'letterDate', header: 'Data pisma', columnType: 'date' },
            { field: 'correspondenceType', header: 'Typ korespondencji' },
            { field: 'letterNumber', header: 'Numer pisma' }
            ]">
          </app-ngrid>
        </div>
        </div>
        <div class="col-12">
          <div class="shadow-5 whitearea">
          <app-ngrid [title]="'Ulgi na składki'" [lazy]="false" [apiMethod]="'diagnosticians/getExclusions'" [selectMode]="'none'"
            [cols]="[
            { field: 'exclusionContribution.exclusionName', header: 'Powód wyłączenia' },
            { field: 'exclusionContribution.percent', header: 'Procent', columnType: 'percent' },
            { field: 'exclusionFromDate', header: 'Data od', columnType: 'date' },
            { field: 'exclusionToDate', header: 'Data do', columnType: 'date' }
            ]" [additionalData]="additionalData">
          </app-ngrid>
        </div>
        </div>
      </div>
</div>
