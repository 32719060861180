import { Component, OnInit } from '@angular/core';
import { PostService } from '../_services/post.service';

@Component({
  templateUrl: 'home.component.html'
})
export class HomeComponent implements OnInit {
  visiblePosts: any[];
  src: string;
  allposts: boolean = true;
  post: any;
  constructor(private postService: PostService) { }

  async ngOnInit(): Promise<void> {
    let posts = await this.postService.getPostsLazy({});
    this.src = '/assets/ikony pozostale/empty_zdjecie.svg';
    this.visiblePosts = posts.dataSet;
  }
  details(post:any) : void
  {
    this.allposts = false;
    this.post = post;
  }
  all():void{
    this.allposts = true;
  }
}
