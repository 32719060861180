import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Config } from '@app/_models/config';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  private config: Config;

  constructor(private http: HttpClient) {
    this.loadConfig().subscribe();
  }

  private loadConfig(): Observable<Config> {
    return this.http.get<Config>('assets/config.json').pipe(
      tap(config => {
        this.config = Object.assign(new Config(), config);
        console.log("Config initialized", this.config);
      })
    );
  }

  public getConfig(): Config {
    return this.config;
  }
}