import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError, EMPTY } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { Router } from '@angular/router';
import { AuthenticationService } from '@app/_services/authentication.service';
import { ToastService } from '@app/_services/toast.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private toastService: ToastService, private authenticationService: AuthenticationService, private router: Router) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {
      if ([401, 403, 404].includes(err.status) && this.authenticationService.userValue) {
        // auto logout if 401 or 403 response returned from api
        if ([403, 401].includes(err.status))
          this.authenticationService.logout();
        else
          this.router.navigate(['/error-page'], { queryParams: { errorcode: err.status } });
      }

      const error = (err && err.error && err.error.message) || err.statusText;
      if ([418].includes(err.status))
        this.toastService.addWarning(error, 6);
      else if ([401].includes(err.status))
        this.toastService.addWarning("Brak uprawnień.", 6);
      else if ([404].includes(err.status))
        this.toastService.addWarning("Brak zgodności z danymi. Nie znaleziono.", 6);
      else if ([0, 429].includes(err.status)) {
        this.toastService.addWarning("Przekroczono dopuszczalną liczbę operacji. Spróbuj ponownie później.", 6);
        return EMPTY;
      }
      else
        this.toastService.addError("Nieokreślony błąd. Skontaktuj się z administratorem.", 6);
      return throwError(error);
    }))
  }
}
