<div class="page">
  <h5 class="segmentheader">MOJE DOKUMENTY</h5>
  <hr/>
  <div class="grid">
    <div class="col">
      <div class="shadow-5 whitearea">
      <app-ngrid #docGrid [selectMode]="'none'" [title]="'Dokumenty'" [searchable]="true" [exportable]="false" [apiMethod]="'documents'"
        [lazy]="false" (onSelect)="onSelect($event)" (onUnselect)="onUnselect()" (onButtonClick)="openDoc($event)" [cols]="[
        { field:'docFileName',header:'Nazwa dokumentu' },
        { field:'docType',header:'Typ dokumentu' },
        { field: 'createdDate', header: 'Data utworzenia', columnType: 'date' },
        { field: 'category.categoryName', header: 'Typ merytoryczny' },
        { field: 'letterNumber', header: 'Numer dokumentu' },
        { field: 'id', header: 'Pobierz', columnType: 'button' }
        ]"></app-ngrid>
      </div>
</div>
</div>
</div>
