import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.less']
})
export class ErrorPageComponent implements OnInit {
  errorcode :string;
  errorinfo :string;
  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => this.errorcode = params.errorcode)
    this.errorcode === '404' ? this.errorinfo = "Podana strona nie istnieje" : this.errorinfo = "Odmowa dostępu";
    this.errorcode === undefined ? this.errorcode = "500" : this.errorcode;
  }

}
