import { Component, OnInit } from '@angular/core';
import { DocumentService } from '@app/_services/document.service';
import { ToastService } from '@app/_services/toast.service';
import * as fileSaver from 'file-saver';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-diag-applications-data-templates',
  templateUrl: './diag-applications-data-templates.component.html'
})
export class DiagApplicationsDataTemplatesComponent implements OnInit {

  additionalDataAttachments:any;

  constructor(private docService: DocumentService, private toastService: ToastService) { }

  ngOnInit(): void {
  }

  onSelect(event) {
    this.additionalDataAttachments = { letterId: event.data.id };
  }

  onUnselect() {
    this.additionalDataAttachments = null;
  }

  openDoc(val): void{
    this.docService.loadTemplateData(val.fileName).pipe(first()).subscribe({
      next: (response: any) => {
        let blob: any = new Blob([response]);
        fileSaver.saveAs(blob, val.fileName);
      },
      error: () => {
        this.toastService.addError("Nie udało się pobrać pliku ze składnicy.", 6);
      }
    });
  }
}
