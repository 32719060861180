import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { SystemMessage } from '../_models/system-message';
import { SystemMessageService } from '../_services/system-message.service';
import { AuthenticationService } from '../_services/authentication.service';
import { first } from 'rxjs/operators';
import * as fileSaver from 'file-saver';
import { ToastService } from '@app/_services/toast.service';
import { DocumentService } from '@app/_services/document.service';

@Component({
  selector: 'app-system-message',
  templateUrl: './system-message.component.html',
  styleUrls: ['./system-message.component.less']
})
export class SystemMessageComponent implements OnInit {
  unreadMessages: SystemMessage[];
  messageHeader: string;
  messageContent: string;
  messageAttachments: any[] = [];

  @Output() onLastRead = new EventEmitter<any>();

  constructor(private toastService: ToastService,private service: SystemMessageService, private authService: AuthenticationService, private docService: DocumentService) { }

  ngOnInit(): void {
    this.service.messages.subscribe(x=>{
      this.unreadMessages = x?.filter(x => x.isRead == false).sort((n1, n2) => new Date(n2.createdDate).getTime() - new Date(n1.createdDate).getTime());
      this.unreadMessages = Object.assign([], this.unreadMessages);
    });
  }

  messageRead(msg:any): void {
    this.service.messageRead(msg).then(
      () => {
        if(this.unreadMessages.length==1)
          this.onLastRead.emit();
        this.toastService.addSuccess("Wiadomość została usunięta.",3);
        this.service.getMessages(this.authService.userValue.id).subscribe();
      }
    )
  }

  downloadAttachments(attachment:any) {
    this.docService.loadData(attachment.id).pipe(first()).subscribe({
      next: (response: any) => {
        let blob: any = new Blob([response]);
        fileSaver.saveAs(blob, attachment.fileName);
      }
    });
  }

}

