// system
import { NgModule, APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

// primeng
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { MenubarModule } from 'primeng/menubar';
import { DockModule } from 'primeng/dock';
import { FileUploadModule } from 'primeng/fileupload';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService, ContextMenuService } from 'primeng/api'
import { MessageService } from 'primeng/api'
import { AccordionModule } from 'primeng/accordion';
import { StepsModule } from 'primeng/steps';
import { TabMenuModule } from 'primeng/tabmenu';
import { PasswordModule } from 'primeng/password';
import { KeyFilterModule } from 'primeng/keyfilter';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { DialogModule } from 'primeng/dialog';
import { CheckboxModule } from 'primeng/checkbox';
import { ContextMenuModule } from 'primeng/contextmenu';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { InputMaskModule } from 'primeng/inputmask';
import { InputNumberModule } from 'primeng/inputnumber';
import { ToastModule } from 'primeng/toast';
import { PaginatorModule } from 'primeng/paginator';

// helpers
import { JwtInterceptor } from './_helpers/jwt.interceptor';
import { ErrorInterceptor } from './_helpers/error.interceptor';
import { appInitializer } from './_helpers/app.initializer';
import { AuthGuard } from './_helpers/auth.guard';

// components
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { PostComponent } from './home/post-details/post.component';
import { LoginComponent } from './login/login.component';
import { NgridComponent } from "./_viewComponents/nGrid/ngrid.component";
import { RegisterComponent } from './register/register.component';
import { SystemMessageComponent } from './system-message/system-message.component';
import { PasswordForgotComponent } from './register/password-forgot/password-forgot.component';
import { PasswordChangeComponent } from './register/password-change/password-change.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import { FinancesComponent } from './diagnostician/finances/finances.component';
import { DiagApplicationsComponent } from './diagnostician/diag-applications/diag-applications.component';
import { BadgeModule } from 'primeng/badge';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { DiagDocumentsComponent } from './diagnostician/diag-documents/diag-documents.component';
import { DiagPersonalDataComponent } from './diagnostician/diag-personal-data/diag-personal-data.component';
import { DiagApplicationsDataTemplatesComponent } from './diagnostician/applications/diag-applications-data-templates/diag-applications-data-templates.component';
import { LoginVerifierComponent } from './login/login-verifier/login-verifier.component';

// services
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { AuthenticationService } from './_services/authentication.service';
import { DiagnosticianService } from './_services/diagnostician.service';
import { DocumentService } from './_services/document.service';
import { FormatService } from './_services/format.service';
import { FontService } from './_services/font.service';
import { PostService } from './_services/post.service';
import { SystemMessageService } from './_services/system-message.service';
import { ToastService } from './_services/toast.service';
import { ConfigService } from './_services/config.service';

// pipes
import { SanitizePipe } from './_helpers/sanitize.pipe';
import { FileIconPipe } from './_helpers/icon.pipe';

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    ReactiveFormsModule,
    ConfirmDialogModule,
    FormsModule,
    HttpClientModule,
    ButtonModule,
    TableModule,
    InputTextModule,
    BrowserAnimationsModule,
    AccordionModule,
    TabMenuModule,
    StepsModule,
    PasswordModule,
    KeyFilterModule,
    MessagesModule,
    MessageModule,
    DialogModule,
    CheckboxModule,
    ContextMenuModule,
    TriStateCheckboxModule,
    CalendarModule,
    DropdownModule,
    InputMaskModule,
    FileUploadModule,
    InputNumberModule,
    ToastModule,
    DockModule,
    MenubarModule,
    ProgressSpinnerModule,
    PaginatorModule,
    BadgeModule,
    OverlayPanelModule,
    InputTextareaModule,
    NgIdleKeepaliveModule.forRoot(),
    RouterModule.forRoot([
      { path: '', component: HomeComponent, canActivate: [AuthGuard] },
      { path: 'error-page', component: ErrorPageComponent },
      { path: 'login', component: LoginComponent },
      { path: 'register', component: RegisterComponent },
      { path: 'password-forgot', component: PasswordForgotComponent },
      { path: 'change-password', component: PasswordChangeComponent },
      { path: 'messagesPanel', component: SystemMessageComponent, canActivate: [AuthGuard] },
      { path: 'myProfile', component: MyProfileComponent, canActivate: [AuthGuard] },
      { path: 'diagPersonalData', component: DiagPersonalDataComponent, canActivate: [AuthGuard] },
      { path: 'diagFinances', component: FinancesComponent, canActivate: [AuthGuard] },
      { path: 'diagDocuments', component: DiagDocumentsComponent, canActivate: [AuthGuard] },
      { path: 'diagApplications', component: DiagApplicationsComponent, canActivate: [AuthGuard] },
      { path: '**', redirectTo: '' }
    ], { relativeLinkResolution: 'legacy', scrollPositionRestoration: 'enabled' })
  ],
  declarations: [
    SanitizePipe,
    FileIconPipe,
    AppComponent,
    PostComponent,
    HomeComponent,
    LoginComponent,
    NgridComponent,
    FinancesComponent,
    SystemMessageComponent,
    RegisterComponent,
    PasswordForgotComponent,
    PasswordChangeComponent,
    ErrorPageComponent,
    MyProfileComponent,
    DiagDocumentsComponent,
    DiagPersonalDataComponent,
    DiagApplicationsComponent,
    DiagApplicationsDataTemplatesComponent,
    LoginVerifierComponent
  ],
  providers: [
    { provide: APP_INITIALIZER, useFactory: appInitializer, multi: true, deps: [AuthenticationService, ConfigService] },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    ConfirmationService,
    MessageService,
    ContextMenuService,
    SystemMessageService,
    DocumentService,
    PostService,
    DiagnosticianService,
    ToastService,
    FormatService,
    FontService,
    ConfigService
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }
