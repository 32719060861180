import { Component, OnInit, Input, Output, SimpleChanges } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import { environment } from '@environments/environment';
import { EventEmitter } from '@angular/core';
import { LazyLoadEvent } from 'primeng/api';
import { Table } from 'primeng/table';
import { FontService } from '@app/_services/font.service';

interface GridColumn {
  header: string;
  field: string;
  columnType: string;
  filter?: { type: string; display: string };
}

@Component({
  selector: 'app-ngrid',
  templateUrl: 'ngrid.component.html'
})

export class NgridComponent implements OnInit {
  @Input() title: string = null;
  @Input() selectFirst: boolean = false;
  @Input() loadOnInit: boolean = true;
  @Input() apiMethod: string; // endpoint do ktorego grid ma strzelac
  @Input() search: string[]; // kolumny po ktorych ma szukac wyszukiwara glowna
  @Input() selectMode: string = 'single'; // multiple , none
  @Input() static: boolean = false; // oznacza czy dane do grida bedziemy ladowac statycznie
  @Input() lazy: boolean = true; // czy lazy loading
  @Input() sortable: boolean = true; // czy sortowalny
  @Input() searchable: boolean = false; // czy wyszukiwanie glowna wyszukiwara
  @Input() exportable: boolean = true; // czy exporty do plikow
  @Input() filterable: boolean = false; // czy filtry do plikow
  @Input() scrollHeight: number = 450; // wysokosc scrolla jak skorolowalny
  @Input() rowsPerPage: number = 5; // wierszy na strone
  @Input() cols: GridColumn[] = []; // struktura kolumn
  @Input() additionalData: any; // additional data wysylana do backendu
  @Input() data: any[] = []; // dane do grida jesli uzupelniany statycznie
  @Output() onSelect = new EventEmitter<any>();  // event emitowany na selecie rowa
  @Output() onUnselect = new EventEmitter<any>(); // event emitowany na unselecie rowa
  @Output() onButtonClick = new EventEmitter<any>(); // event emitowany na unselecie rowa
  @Output() onLoad = new EventEmitter<any>(); // event emitowany na zaladowaniu danych

  initialData: any[] = [];
  globalFilter: string; // zmienna podpieta pod wyszukiware
  lazyEvent: LazyLoadEvent; // ostatni lazy event
  apiUrl: string; // pelen adres backendu
  totalRecords: number = 0; // ilosc wierszy total
  selectedData: any[] | any = []; // wybrane wiersze z grida
  exportColumns: any[] = []; // kolumny do exportu w plikach
  loading: boolean = true; // flaga odpowiedzialna za kreciolka ladowania na gridzie

  constructor(private httpClient: HttpClient, public fontService: FontService) {
  }

  ngOnInit(): void {
    this.prepareForGridLoading();
    
    if (this.filterable)
      this.setColumnsFilterConfig();

    if (this.exportable)
      this.setExportColumns();
  }

  private prepareForGridLoading(): void {
    if (this.static) {
      this.prepareStaticData();
    } else {
      this.apiUrl = `${environment.apiUrl}${this.apiMethod}`;
      if (this.loadOnInit) this.refresh();
    }

    if (this.static || !this.loadOnInit) this.loading = false;
  }

  private prepareStaticData(): void {
    this.lazy = false;
    this.initialData = Object.assign([], this.data);
    this.totalRecords = this.data.length;
  }

  private setExportColumns(): void {
    this.exportColumns = this.cols.map(col => ({
      title: col.header,
      dataKey: col.field,
      columnType: col.columnType
    }));
  }
  
  setColumnsFilterConfig(){
    this.cols = this.cols.map((col) => {
      const filter = this.getColumnFilterConfig(col.columnType);
      return {...col, filter};
    });
  }

  // domyślnie columnType = 'string'
  getColumnFilterConfig(columnType: string) {
    const config = {
      'string': { type: 'text', display: 'menu' },
      'number': { type: 'numeric', display: 'menu' },
      'decimal': { type: 'numeric', display: 'menu' },
      'bool': { type: 'boolean', display: 'menu' },
      'datetime': { type: 'date', display: 'menu' },
      'date': { type: 'date', display: 'menu' },
    };
    
    return config[columnType] || config['string'];
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['additionalData']) {
      if (this.additionalData && !this.loading)
        this.refresh();
    }

    if (changes['data']) {
      if (this.static) {
        this.totalRecords = this.data ? this.data.length : 0;
        this.data = Object.assign([], this.data);
      }
    }
  }

  // domyślnie columnType = 'string'
  getColumnType = (columnType: string = 'string', field: string): string => field.split('.').length > 1 ? 'nested' : columnType;

  getNestedValue(data: any, field: string, columnType: string): any {
    const fields = field.split('.');
    let value = data;

    for (let f of fields) {
      if (value == null) {
        return null;
      }
      value = value[f];
    }
    
    if (columnType === 'date') {
        return value ? this.formatDate(new Date(value)) : value;
    }

    return value;
  }

  formatDate(date: Date): string {
    const day = date.getDate().toString().padStart(2, '0'); // Dzień z wiodącym zerem
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Miesiąc z wiodącym zerem (zakładając numerację od 0)
    const year = date.getFullYear();
    
    return `${day}.${month}.${year}`;
}

  getMonth(month: number): string {
    const monthNames = [
      "Styczeń",
      "Luty",
      "Marzec",
      "Kwiecień",
      "Maj",
      "Czerwiec",
      "Lipiec",
      "Sierpień",
      "Wrzesień",
      "Październik",
      "Listopad",
      "Grudzień"
    ];
    return monthNames[month - 1];
  };

  clearData = (): void => {
    this.data = [];
    this.totalRecords = 0;
    this.unselect();
  }

  unselect = (): void => {
    this.selectedData = [];
  }

  clear = (table: Table): void => {
    this.globalFilter = "";
    table.filters = {}
    table.clear();
    if (!this.static)
      this.refresh();
    if (this.static) {
      for (var item of this.data) {
        if (!this.initialData.find(x => x.id === item.id))
          this.initialData.unshift(item);
      }
      this.data = Object.assign([], this.initialData);
    }
    this.onUnselect.emit();
  }

  refresh = (): void => {
    this.loading = true;
    this.unselect();
    this.lazy ? this.refreshGridLazy(this.lazyEvent) : this.refreshGrid();
  }

  refreshGridLazy = (event: LazyLoadEvent): void => {
    this.lazyEvent = event;
    if ((this.loadOnInit || this.additionalData) && this.lazyEvent) {
      let obj = { lazyEvent: JSON.stringify(event), search: this.search, additionalData: JSON.stringify(this.additionalData) };
      this.httpClient.get<any>(this.apiUrl, { params: obj })
        .subscribe(data => {
          this.data = data.dataSet;
          this.totalRecords = data?.totalRecords;
          this.loading = false;
          this.onLoad.emit();
          if (this.selectFirst && this.data && this.data.length > 0) {
            if (this.selectMode === "multiple") {
              this.selectedData.push(this.data[0]);
              this.selectedData = Object.assign([], this.selectedData);
            } else {
              this.selectedData = this.data[0];
            }
            let event = { data: this.data[0] }
            this.onRowSelect(event);
          }
        });
    }
  }

  refreshGrid = (): void => {
    this.httpClient.get<any>(this.apiUrl, { params: { additionalData: JSON.stringify(this.additionalData) } })
      .subscribe(data => {
        this.data = data;
        this.totalRecords = data?.length;
        this.loading = false;
        this.onLoad.emit();
        if (this.selectFirst && this.data && this.data.length > 0) {
          if (this.selectMode === "multiple") {
            this.selectedData.push(this.data[0]);
            this.selectedData = Object.assign([], this.selectedData);
          } else
            this.selectedData = this.data[0];

          let event = { data: this.data[0] }
          this.onRowSelect(event);
        }
      });
  }

  onRowSelect = (event: any): void => {
    this.onSelect.emit(event);
  }

  onColumnButtonClick = (event: any): void => {
    this.onButtonClick.emit(event);
  }

  onRowUnselect = (event: any): void => {
    this.onUnselect.emit(event);
  }

  async createDataToExport(type: string = 'EXCEL'): Promise<any[]> {
    let data = this.data;
    if (this.lazy) {
      try {
        this.loading = true;
        let lazyEventAll = Object.assign({}, this.lazyEvent);
        lazyEventAll.rows = this.totalRecords;
        let obj = { lazyEvent: JSON.stringify(lazyEventAll), search: this.search, additionalData: JSON.stringify(this.additionalData) };
        data = (await this.httpClient.get<any>(this.apiUrl, { params: obj }).toPromise()).dataSet;
      }
      finally {
        this.loading = false;
      }
    }
    let dataToReturn: any[] = [];
    for (var item of this.exportColumns) {
      var spliteditem = item.dataKey.split('.');
      if (spliteditem.length == 1) {
        for (var dataRow in data) {
          if (!dataToReturn[dataRow])
            dataToReturn[dataRow] = {};
          let val = data[dataRow][item.dataKey];
          if (val !== null) {
            if (item.columnType === "bool") {
              if (val === true)
                val = "Tak"
              else
                val = "Nie";
            }
            else if (item.columnType === "date")
              val = val.split("T")[0];
            else if (item.columnType === "datetime")
              val = val.split("T")[0] + " " + val.split("T")[1].split(".")[0];
            else if (item.columnType === "month")
              val = this.getMonth(val);
          }
          dataToReturn[dataRow][item.dataKey] = val;
        }
      } else if (spliteditem.length == 2) {
        for (var dataRow in data) {
          if (!dataToReturn[dataRow])
            dataToReturn[dataRow] = {};
          let val = data[dataRow][spliteditem[0]] != null ? data[dataRow][spliteditem[0]][spliteditem[1]] : null;
          if (val !== null) {
            if (item.columnType === "bool") {
              if (val === true)
                val = "Tak"
              else
                val = "Nie";
            }
            else if (item.columnType === "date")
              val = val.split("T")[0];
            else if (item.columnType === "datetime")
              val = val.split("T")[0] + " " + val.split("T")[1].split(".")[0];
            else if (item.columnType === "month")
              val = this.getMonth(val);

          }
          dataToReturn[dataRow][item.dataKey] = val;
        }
      }
    }
    return dataToReturn;
  }

  async exportPdf(): Promise<void> {
    const doc = new jsPDF('p', 'pt');
    doc.addFileToVFS('ARIAL-normal.ttf', this.fontService.font);
    doc.addFont('ARIAL-normal.ttf', 'ARIAL', 'normal');
    doc.setFont("ARIAL");
    autoTable(doc, {
      columns: this.exportColumns,
      theme: "grid",
      body: await this.createDataToExport('PDF'),
      styles:
      {
        font: 'ARIAL',
        fontStyle: 'normal'
      }
    });
    doc.save("data" + '_export_' + new Date().getTime() + ".pdf");
  }
}
