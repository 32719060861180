import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FormatService {

  constructor() { }

  clean(obj: any) {
    for (var propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined) {
        delete obj[propName];
      }
    }
    return obj
  }

  toDate(val: any): Date {
    if (val) {
      if (val instanceof Date)
        return val;
      let date = new Date(val);
      return date;
    }
    return null;
  }

  formatDatesInRequestBody(body: any): any {
    for (let key of Object.keys(body)) {
      if (body[key] instanceof Date)
        body[key] = this.toUTCString(body[key]);
      if (body[key] instanceof Array) {
        for(let row of body[key])
        {
        for (let keyek of Object.keys(row)) {
          if (row[keyek] instanceof Date)
          row[keyek] =  this.toUTCString(row[keyek]);
        }
      }
    }
    }
    return body;
  }

  uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  private toUTCString(val: Date): string
  {
    let valLocString = val.toLocaleString();
    let valTime = valLocString.split(', ');
    let valArr = valTime[0].split('.');
    return `${valArr[2]}-${this.ensureRightMonthAndDayFormat(valArr[1])}-${this.ensureRightMonthAndDayFormat(valArr[0])}T${valTime[1]}`
  }

  private ensureRightMonthAndDayFormat(monthOrDay:string):string {
    return monthOrDay.length === 2 ? monthOrDay : "0" + monthOrDay;
  }
}
