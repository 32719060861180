import { Component } from '@angular/core';
import { AuthenticationService } from '@app/_services/authentication.service';

@Component({
  selector: 'app-finances',
  templateUrl: './finances.component.html'
})
export class FinancesComponent {

  additionalData: any = {};


  constructor(private authService: AuthenticationService) {
    this.additionalData = authService.userValue?.diagnosticianId;
  }

}
