import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { SystemMessage } from '../_models/system-message';

@Injectable({
  providedIn: 'root'
})
export class SystemMessageService {

  private messagesSubject: BehaviorSubject<any>;
  public messages: Observable<any>;

  constructor(private http:HttpClient) {
    this.messagesSubject = new BehaviorSubject<any>(null);
    this.messages = this.messagesSubject.asObservable();
  }

  getMessages(userId:number) {
    return this.http.get(`${environment.apiUrl}messenger/get-user-messages/${userId}`).pipe(map(msgs => { this.messagesSubject.next(msgs) }));
  }

  async getSystemMessages(userId:number):Promise<SystemMessage[]> {
    return <SystemMessage[]>(await this.http.get(`${environment.apiUrl}messenger/get-user-messages/${userId}`).toPromise());
  }

  async messageRead(message: SystemMessage):Promise<Object> {
    return this.http.patch(`${environment.apiUrl}messenger/change-message-status`,  message.id).toPromise();
  }
  async messageDelete(message: SystemMessage):Promise<Object> {
    return this.http.delete(`${environment.apiUrl}messenger/delete-message/${message.id}`).toPromise();
  }

  async sendMessage(body:any, type:string):Promise<void> {
    const endpointEnd = (type) ? `-${type}` : "";
    this.http.post(`${environment.apiUrl}messenger/send-message${endpointEnd}`, body).toPromise();
  }
}
