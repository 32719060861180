<p-dialog header="Weryfikacja użytkownika" [closable]='false' [(visible)]="!isUserVerified"
    [breakpoints]="{ '960px': '75vw' }" [style]="{ width: '500px' }" [modal]="true" [draggable]="false"
    [resizable]="false">
    <form class="row" [formGroup]="verifyAccountForm" (ngSubmit)="onSubmit()">
        <div class="form-group col-12">
            <label style="font-size:14px" htmlFor="diagnosticianOriginalId">Podaj swój numer wpisu na listę</label>
            <p-inputNumber id="inputNumberDiagOrgId" formControlName="diagnosticianOriginalId"
                [ngClass]="{ 'is-invalid': submitted && f.diagnosticianOriginalId.errors }">
            </p-inputNumber>
            <div style="font-size:14px" *ngIf="submitted && f.diagnosticianOriginalId.errors"
                class="invalid-feedback small">
                <div *ngIf="f.diagnosticianOriginalId.errors?.required">Pole jest wymagane!</div>
                <div *ngIf="f.diagnosticianOriginalId.errors?.min || f.diagnosticianOriginalId.errors?.max">Numer wpisu
                    nieprawidłowy</div>
            </div>
        </div>
        <div class="form-group md:col-6 col-12">
            <label style="font-size:14px" htmlFor="subAccountStartPattern">Podaj pierwsze 2 znaki swojego subkonta
                bankowego</label>
            <p-inputMask type="text" formControlName="subAccountStartPattern"
                [ngClass]="{ 'is-invalid': submitted && f.subAccountStartPattern.errors }" mask="99" placeholder="99">
            </p-inputMask>
            <div style="font-size:14px" *ngIf="submitted && f.subAccountStartPattern.errors"
                class="invalid-feedback small">
                <div *ngIf="f.subAccountStartPattern.errors?.required">Pole jest wymagane!
                </div>
                <div *ngIf="f.subAccountStartPattern.errors?.pattern">Dozwolone wyłącznie
                    cyfry, długość musi wynosić 2 znaki!</div>
            </div>
        </div>
        <div class="form-group md:col-6 col-12">
            <label style="font-size:14px" htmlFor="subAccountEndPattern">Podaj ostatnie 6 znaków swojego subkonta
                bankowego</label>
            <p-inputMask type="text" formControlName="subAccountEndPattern"
                [ngClass]="{ 'is-invalid': submitted && f.subAccountEndPattern.errors }" mask="999999"
                placeholder="999999">
            </p-inputMask>
            <div style="font-size:14px" *ngIf="submitted && f.subAccountEndPattern.errors"
                class="invalid-feedback small">
                <div *ngIf="f.subAccountEndPattern.errors?.required">Pole jest wymagane!</div>
                <div *ngIf="f.subAccountEndPattern.errors?.pattern">Dozwolone wyłącznie cyfry,
                    długość musi wynosić 6 znaków!</div>
            </div>
        </div>
        <button [disabled]="loading" class="btn btn-primary mt-2 mb-2">
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
            Zweryfikuj konto
        </button>
        <div *ngIf="error" style="font-size:12px" class="alert alert-danger mb-2">{{error}}</div>
    </form>
    <div class="card">
        <div class="card-body" style="font-size:12px">
            Informujemy, iż zostały podjęte działania mające na celu podniesienie 
            poziomu bezpieczeństwa danych osobowych zawartych w portalu oraz właściwego ich 
            zabezpieczenia przed dostępem osób nieuprawnionych. Kwestia odpowiedniego zabezpieczenia 
            danych w portalu jest priorytetem oraz kluczowym zagadnieniem dla działalności KIDL i zaufania, 
            jakim cieszy się zawód diagnosty laboratoryjnego. Dlatego prosimy o wyrozumiałość i prosimy o uzupełnienie 
            dodatkowych danych weryfikacyjnych.
        </div>
    </div>
</p-dialog>
