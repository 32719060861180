<div class="page">
  <h5 class="segmentheader">MOJE DANE</h5>
  <hr />
  <p-overlayPanel #persondatainfo>
    <ng-template pTemplate>
      {{element}} nie jest uzupełniony. Aby to zrobić, <a routerLink="/diagApplications">złoż WNIOSEK</a>
    </ng-template>
  </p-overlayPanel>
  <div class="grid">
    <div class="md:col-6 col-12">
      <div class="shadow-5 whitearea">
        <div style="padding:8px 8px 8px 16px">
          <label class="tableheader tabletitle">Dane podstawowe</label>
        </div>
        <div class="row">
          <div class="col-fixed text-center diagimgdiv">
            <img id="Diagimg" *ngIf="diagnosticianPhoto" [src]="diagnosticianPhoto | safe" alt="Zdjęcie diagnosty">
            <ng-container *ngIf="!diagnosticianPhoto">
              <img id="Diagimg" *ngIf="gender==='Kobieta'" src="/assets/imagesAndLogos/nophotowoman.png"
                alt="Brak zdjęcia">
              <img id="Diagimg" *ngIf="gender==='Mężczyzna'" src="/assets/imagesAndLogos/nophotoman.png"
                alt="Brak zdjęcia">
            </ng-container>
          </div>
          <div class="col text-break">
              <div class="col">
                <label class="tableheader">{{firstName}} {{secondName}} {{lastName}}</label>
                <br />
                <label>{{capitalizeFirstLetter(jobTitle)}} <span *ngIf="jobTitle && academicTitle">|</span> {{capitalizeFirstLetter(academicTitle)}}</label>
              </div>
              <div class=" col d-flex">
                <label style="flex-basis:35%" class="blue mr-2">Numer wpisu: </label><label style="flex-basis:65%" >{{diagnosticianOrginalId}}</label>
              </div>
              <div class=" col d-flex">
                <label style="flex-basis:35%" class="blue mr-2">Numer uchwały: </label><label style="flex-basis:65%" >{{krdlResolutionNumberEntered}}</label>
              </div>
              <div class=" col d-flex">
                <label style="flex-basis:35%" class="blue mr-2">Numer PWZDL: </label><label style="flex-basis:65%" >{{pwzdlNumber}}</label>
              </div>
              <br />
              <div class=" col d-flex">
                <label style="flex-basis:35%" class="blue mr-2">Telefon: </label><label style="flex-basis:65%"
                  *ngIf="mobilePhoneNumber">{{mobilePhoneNumber}}</label><span *ngIf="!mobilePhoneNumber">-<i
                    (click)="persondatainfo.toggle($event);element='Numer telefonu'"
                    class="pi pi-question pi-data"></i></span>
              </div>
              <div class=" col d-flex">
                <label style="flex-basis:35%" class="blue mr-2">E-mail: </label><label style="flex-basis:65%"  *ngIf="email">{{email}}</label><span *ngIf="!email">-<i
                    (click)="persondatainfo.toggle($event);element='E-mail'" class="pi pi-question pi-data"></i></span>
              </div>
              <div class=" col d-flex">
                <label style="flex-basis:35%" class="tableheader">Adres</label>
              </div>
              <div class=" col d-flex">
                <label style="flex-basis:35%" class="blue mr-2">Miejscowość: </label><label style="flex-basis:65%" >{{city}}</label>
              </div>
              <div class=" col d-flex">
                <label style="flex-basis:35%" class="blue mr-2">Ulica: </label><label style="flex-basis:65%" >{{street}}</label>
              </div>
              <div class=" col d-flex">
                <label style="flex-basis:35%" class="blue mr-2">Województwo: </label><label style="flex-basis:65%" >{{voivodeship}}</label>
              </div>
              <div class=" col d-flex">
                <label style="flex-basis:35%" class="blue mr-2">Kod pocztowy:</label> <label style="flex-basis:65%" >{{postalCode}}</label>
              </div>
          </div>
        </div>
        <div>
          <p-checkbox (click)="confirm()" [readonly]="true" class="mt-2 mb-4 ml-4"
            label="Zgoda na doręczanie przez KIDL pism za pomocą środków komunikacji elektronicznej"
            [(ngModel)]="areAgreementsAccepted" binary="true" inputId="areAgreementsAccepted">
          </p-checkbox>
        </div>
      </div>
    </div>
    <div class=" md:col-6 col-12" hidden>
      <div style="height:100%" class="shadow-5 whitearea">
      <app-ngrid #epuPointGrid [title]="'Punkty edukacyjne'" [static]="true" [data]="educationPeriods" [cols]="[
                { field: 'periodFrom', header: 'Okres od', columnType: 'number' },
                { field: 'periodTo', header: 'Okres do', columnType: 'number' },
                { field: 'fulfilled', header: 'Spełniono', columnType: 'bool' },
                { field: 'pointsQuantity', header: 'Liczba punktów', columnType: 'number' }
                ]">
      </app-ngrid>
    </div>
    </div>
    <div class="md:col-6 col-12">
      <div style="height:100%" class="shadow-5 whitearea">
      <app-ngrid #specGrid [title]="'Specjalizacje'" [exportable]="false" [static]="true" [data]="specializations"
        [cols]="[
            { field: 'specialization.specialization', header: 'Specjalizacja' },
            { field: 'specializationType', header: 'Rodzaj' },
            { field: 'specializationIsComplete', header: 'Ukończona' },
            { field: 'specializationTrainingUnit', header: 'Jednostka szkoląca' },
            { field: 'completionNumber', header: 'Nr/Rok nadania' }
            ]">
      </app-ngrid>
    </div>
    </div>
    <div class="md:col-6 col-12">
      <div style="height:100%" class="shadow-5 whitearea">
      <app-ngrid #labGrid [title]="'Zatrudnienie'" [static]="true" [exportable]="false" [data]="laboratories" [cols]="[
          { field: 'laboratory.labFullName', header: 'Nazwa' },
          { field: 'from', header: 'Okres od', columnType: 'date' },
          { field: 'to', header: 'Okres do', columnType: 'date' },
          ]">
      </app-ngrid>
    </div>
    </div>
  </div>
</div>
