import { Component } from '@angular/core';
import { DiagnosticianEducationPeriod } from '@app/_models/diagnostician-educationperiod';
import { DiagnosticianLab } from '@app/_models/diagnostician-lab';
import { DiagnosticianSpecialization } from '@app/_models/diagnostician-specialization';
import { AuthenticationService } from '@app/_services/authentication.service';
import { ToastService } from '@app/_services/toast.service';
import { UserService } from '@app/_services/user.service';
import { DiagnosticianService } from '@app/_services/diagnostician.service';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-diag-personal-data',
  templateUrl: './diag-personal-data.component.html',
  styleUrls: ['./diag-personal-data.component.less']
})
export class DiagPersonalDataComponent {
  educationPeriods: DiagnosticianEducationPeriod[] = [];
  specializations: DiagnosticianSpecialization[] = [];
  laboratories: DiagnosticianLab[] = [];
  diagnosticianPhoto: any;
  gender: any;
  firstName: string;
  secondName: string;
  lastName: string;
  formerLastName: string;
  jobTitle: string;
  academicTitle: string;
  pwzdlIssueDate: Date;
  pwzdlNumber: string;
  diagnosticianOrginalId: number;
  pesel: string;
  identificationDocumentType: string;
  identificationDocumentNumber: string;
  postalCode: string;
  city: string;
  voivodeship: string;
  street: string;
  mobilePhoneNumber: string;
  phoneNumber2: string;
  phoneNumber: string;
  email: string;
  areAgreementsAccepted: boolean;
  krdlResolutionNumberEntered: string;
  element: string;

  constructor(private authService: AuthenticationService, private service: DiagnosticianService, private userService: UserService, private toastService: ToastService, private confirmationService: ConfirmationService) {
    this.toastService.putInMask();
    this.service.getLoggedDiagnosticianInfo().then((data) => {
      if (data.photoId) {
        this.service.getLoggedDiagnosticianPhoto().then((photo) => { if (photo) { this.diagnosticianPhoto = 'data:image/jpeg;base64,' + photo.base64string; } });
      }
      this.krdlResolutionNumberEntered = data.krdlResolutionNumberEntered;
      this.academicTitle = data.academicTitle;
      this.areAgreementsAccepted = data.areAgreementsAccepted;
      this.city = data.city;
      this.diagnosticianOrginalId = data.diagnosticianOrginalId;
      this.educationPeriods = data.educationPeriods;
      this.email = data.email;
      this.firstName = data.firstName;
      this.formerLastName = data.formerLastName;
      this.gender = data.gender === false ? "Mężczyzna" : "Kobieta"
      this.identificationDocumentNumber = data.identificationDocumentNumber;
      this.identificationDocumentType = data.identificationDocumentType;
      this.jobTitle = data.jobTitle;
      this.lastName = data.lastName;
      this.mobilePhoneNumber = data.mobilePhoneNumber;
      this.pesel = data.pesel;
      this.phoneNumber = data.phoneNumber;
      this.phoneNumber2 = data.phoneNumber2;
      this.postalCode = data.postalCode;
      this.pwzdlIssueDate = data.pwzdlIssueDate;
      this.pwzdlNumber = data.pwzdlNumber;
      this.secondName = data.secondName;
      this.street = data.street;
      this.voivodeship = data.voivodeship;
      this.laboratories = data.laboratories;
      this.specializations = data.specializations;
      this.toastService.putOutMask();
    });
  }
  public capitalizeFirstLetter(string: string) {
    if (string)
      return string.charAt(0).toUpperCase() + string.slice(1);
    else
      return "";
  }
  confirm() {
    this.confirmationService.confirm({
      message: '<div class="text-center bold">Czy na pewno chcesz zmienić zgodę na: <br/> <div class="blue mt-6">Doręczenie przez KIDL pism za pomocą środków komunikacji elektronicznej?</div></div>',
      accept: () => {
        let body = {
          userId: this.authService.userValue.id,
          agreement: !this.areAgreementsAccepted
        };
        this.userService.patchUserInfo(body).pipe().subscribe({
          next: () => {
            this.authService.refreshToken().subscribe();
            this.areAgreementsAccepted = !this.areAgreementsAccepted;
            this.toastService.addSuccess("Zaktualizowano dane osobowe.", 6);
          }
        });
      }
    });
  }
}

