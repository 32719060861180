<div class = "center mt-8">
<svg xmlns="http://www.w3.org/2000/svg" id="robot-error" viewBox="0 0 260 118.9">
  <defs>
   <text id="text-s" class="error-text" y="106" x = "32"> {{errorcode}} </text>
  </defs>
    <path class="alarm" fill="#e62326" d="M125.6 24V9.1c0-5 4.1-9.1 9.1-9.1h0c5 0 9.1 4.1 9.1 11.1v10.6" />
   <use xlink:href="#text-s" x="-0.5px" y="-1px" fill="black"></use>
   <use xlink:href="#text-s" fill="#2b2b2b"></use>
</svg>
<h1>{{errorinfo}}</h1>
<h2>Wróc do <a routerLink="/">Strony Głównej!</a></h2>
</div>
