import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { UserService } from '../../_services/user.service';

@Component({
  templateUrl: './password-change.component.html'
})
export class PasswordChangeComponent implements OnInit {
  form: FormGroup;
  token: string;

  error: string;
  displayCompleteDialog = false;

  loading = false;
  submitted: boolean = false;
  readonly passwordMinLenght: number = environment.passwordMinLenght;

  constructor(
    private formBuilder: FormBuilder,
    private service: UserService,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      password: ['', [Validators.pattern(environment.passwordRegex),Validators.required, Validators.minLength(environment.passwordMinLenght)]],
      password2: ['', [Validators.pattern(environment.passwordRegex),Validators.required, Validators.minLength(environment.passwordMinLenght)]]
    })
    this.route.queryParams.subscribe(params => this.token = params.token)
  }

  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }
  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }
    this.sendData();
  }
  sendData(): void {
    if (this.f.password.value != this.f.password2.value) {
      this.error = "Hasła nie są jednakowe!";
      return;
    }
    this.loading = true;
    const body = {
      token: this.token,
      newPassword: this.f.password.value,
    }

    this.changePassword(body);
  }

  private changePassword(requestBody: any): void {
    this.service.changePassword(requestBody)
      .pipe()
      .subscribe({
        next: () => {
          this.displayCompleteDialog = true;
        },
        error: error => {
          this.loading = false;
          this.error = error;
        }
      });
  }
}
