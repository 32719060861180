import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  templateUrl: 'post.component.html',
  selector: 'post',
})
export class PostComponent implements OnInit {
  @Input() post: any = null;
  @Output() onReturn = new EventEmitter<any>();
  src: string = '/assets/ikony pozostale/empty_zdjecie.svg';
  constructor() { }

  async ngOnInit(): Promise<void> {

  }
  return() : void{
    this.onReturn.emit();
  }

}
