import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileIcon'
})
export class FileIconPipe implements PipeTransform {

  transform(value: any): any {
    let classString: string;
    switch(value) {
      case 'JPG':
      case 'PNG':
        classString = 'p-2 m-2 pi pi-image';
        break;
      case 'PDF':
        classString = 'p-2 m-2 pi pi-file-pdf';
        break;
      case 'DOC':
      case 'DOCX':
        classString = 'p-2 m-2 pi pi-file';
        break;
      case 'TXT':
        classString = 'p-2 m-2 pi pi-file-text';
        break;
      case 'XLS':
      case 'XLSX':
        classString = 'p-2 m-2 pi pi-file-excel';
        break;
      default:
        classString = 'p-2 m-2 pi pi-file';
    }
    return classString;
  }
}