<div class="page">
  <ng-container *ngIf="allposts">
    <h5 class="segmentheader">AKTUALNOŚCI</h5>
    <hr />
    <div class="grid">
      <ng-container *ngFor="let post of visiblePosts">
        <div class="col-12" [ngClass]="{'md:col-8':post.image,'md:col-4':!post.image}">
            <div class="grid shadow-5 whitearea m-1" style="min-height:352px">
              <div *ngIf="post.image" class="col-fixed p-0" style="width:272px">
                <img [src]="post.image ? 'data:image/jpeg;base64,' + post.image.content : src"
                  [ngStyle]="!post.image  && {'padding':'7em'}"
                  style="border:1px solid;border-color:#D0D8E0;width:100%;height:352px;border-radius:15px">
              </div>
              <div class="col p-5 grey flex flex-column">
                <label class="tableheader mt-4">{{post.title}}</label>
                <div style="overflow-wrap: break-word;
                word-wrap: break-word;
                -webkit-hyphens: auto;
                -ms-hyphens: auto;
                -moz-hyphens: auto;
                word-break: break-all;
                hyphens: auto;" class="flex-grow-1" [innerHTML]="post.content.substring(0,306) + (post.content.length > 307 ? '...' : '') | sanitize">
                </div>
                <div class="flex flex-wrap justify-content-between align-items-center">
                  <span style="color:#5F5F5F">{{post.createdDate | date:'dd.MM.YYYY'}}</span>
                  <button (click)="details(post)" pButton type="button" label="Czytaj dalej"></button>
                </div>
              </div>
            </div>
        </div>
      </ng-container>
    </div>
  </ng-container>
  <ng-container *ngIf="!allposts">
    <post [post]="post" (onReturn)="all()"></post>
  </ng-container>
</div>
