<div class="loginpage">
    <div class="center loginComponent">
        <img class="logo"
            src="/assets/imagesAndLogos/logo.png"
            alt="KIDL logo">
        <div class="col-md-4 offset-md-4 mt-5">
            <div class="card center">
                <div class="card-body">
                    <form [formGroup]="loginForm"
                        (ngSubmit)="onSubmit()">
                        <div class="form-group">
                            <label style="font-size:14px"
                                for="username">Login</label>
                            <input type="text"
                                formControlName="username"
                                class="mt-1 form-control"
                                [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
                            <div *ngIf="submitted && f.username.errors"
                                class="invalid-feedback">
                                <div *ngIf="f.username.errors?.required">Login jest wymagany</div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label style="font-size:14px"
                                for="password">Hasło</label>
                            <input type="password"
                                formControlName="password"
                                class="mt-1 form-control"
                                [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                            <div *ngIf="submitted && f.password.errors"
                                class="invalid-feedback">
                                <div *ngIf="f.password.errors?.required">Hasło jest wymagane</div>
                            </div>
                        </div>
                        <button type="submit" [disabled]="loading || !this.config.loginEnabled"
                            class="btn btn-primary mt-2 mb-2">
                            <span *ngIf="loading"
                                class="spinner-border spinner-border-sm mr-1"></span>
                            Zaloguj
                        </button>
                        <div *ngIf="error"
                            class="alert alert-danger mt-3 mb-2">{{error}}</div>
                    </form>
                    <ng-container *ngIf="this.config.loginEnabled">
                        <a style="font-size:14px"
                            [disabled]="loading"
                            routerLink="/password-forgot">Nie pamiętasz hasła?</a>
                        <br />
                        <br />
                        <a style="font-size:14px"
                            [disabled]="loading"
                            routerLink="/register">Kliknij, aby złożyć wniosek o konto.</a>
                    </ng-container>
                </div>
            </div>
        </div>
        <app-login-verifier *ngIf="isUserVerified === false && this.config.loginEnabled"
            [isUserVerified]="isUserVerified"
            [username]="f.username.value"
            (isUserVerifiedChange)="handleUserVerification($event)">
        </app-login-verifier>
    </div>
</div>