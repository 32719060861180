<h5 class="segmentheader">ARTYKUŁ</h5>
<hr />
<div class="shadow-5 whitearea flex flex-wrap">
  <div class="col-fixed" *ngIf="post.image"
    style="width:272px;border-top-left-radius:15px;border-bottom-left-radius:15px;">
    <img [src]="post.image ? 'data:image/jpeg;base64,' + post.image.content : src" [ngStyle]="!post.image  && {'padding':'6em'}"
      style="border:1px solid;border-color:#D0D8E0;height:352px;width:100%;border-radius:15px;">
  </div>
  <div class="col flex flex-column flex-grow-1 p-3">
    <label class="tableheader mb-2 grey">{{post.title}}</label>
    <div style="overflow-wrap: break-word;
    white-space: pre-wrap;
    word-wrap: break-word;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    word-break: break-all;
    hyphens: auto;" class="flex-grow-1 grey" [innerHTML]="post.content | sanitize">
    </div>
  </div>
  <div class="inline-flex justify-content-between" style="flex-basis:100%;max-height:45.5px; margin:16px">
    <span style="color:#5F5F5F;align-self:flex-end">Artykuł z dnia: <span class="blue">{{post.createdDate |
      date:'dd.MM.YYYY'}}</span></span>
    <button (click)="return()" pButton type="button" label="Powrót"></button>
  </div>
</div>

