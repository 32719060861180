import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { UserService } from '../../_services/user.service';

@Component({
  templateUrl: './password-forgot.component.html'
})
export class PasswordForgotComponent implements OnInit {
  passwordForgotForm: FormGroup;

  error: string;
  displayCompleteDialog = false;

  submitted = false;
  loading = false;

  constructor(private service: UserService,
    private formBuilder: FormBuilder) { }

    ngOnInit() {
      this.passwordForgotForm = this.formBuilder.group({
        email: ['', [Validators.required,Validators.email]]
      });
  }
// convenience getter for easy access to form fields
 get f() { return this.passwordForgotForm.controls; }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.passwordForgotForm.invalid) {
        return;
    }
    this.sendData();
  }
  sendData(): void {
    this.loading = true;

    const body = {
      email: this.passwordForgotForm.controls.email.value
    }

    this.refreshPassword(body);
  }


  private refreshPassword(requestBody: any): void {
    this.service.refreshPassword(requestBody)
      .pipe()
      .subscribe({
        next: () => {
          this.displayCompleteDialog = true;
        },
        error: error => {
          this.loading = false;
          this.error = error;
        }
      });
  }
}
