<p-dialog [closable] = "false" header="Przypomnienie hasła" [(visible)]="displayCompleteDialog" [breakpoints]="{'960px': '75vw'}" [style]="{width: '50vw'}" [baseZIndex]="10000"
    [draggable]="false" [resizable]="false">
    <p>Na podany adres e-mail* wysłaliśmy wiadomość z dalszymi instrukcjami. Po zamknięciu tego okna zostaniesz przekierowany na stronę logowania.</p>
    <p>* - Jeśli mail nie pojawi się w skrzynce odbiorczej, prosimy sprawdzić folder SPAM</p>
        <ng-template pTemplate="footer">
        <p-button type="button" autofocus routerLink="/" label="Potwierdź" styleClass="p-button-text"></p-button>
        </ng-template>
</p-dialog>
<div class="loginpage">
<div class = "center loginComponent"><img class = "logo" src="/assets/imagesAndLogos/logo.png" alt="KIDL logo">
<div class="col-md-4 offset-md-4 mt-5">
  <div class="card">
    <form [formGroup]="passwordForgotForm" (ngSubmit)="onSubmit()">
      <h5 class="card-header">Przypomnienie hasła</h5>
      <div class="card-body">
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col">
            <label style="font-size:14px" for="email">E-mail</label>
            <input id="email"  class="mt-1 form-control" formControlName="email"  [ngClass]="{'is-invalid': submitted && f.email.errors}" type="email">
            <div class="invalid-feedback">
              <div *ngIf="f.email.errors?.required">E-mail nie został podany!</div>
              <div *ngIf="f.email.errors?.email">Podano nieprawidłowy adres e-mail!</div>
          </div>
          </div>
          <div class="center mt-3">
          <div *ngIf="error" class="alert alert-danger mt-3 mb-2">{{error}}</div>
          <button [disabled]="loading" class="btn btn-primary "><span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Zatwierdź</button>
          <button class="btn btn-primary  ml-1" routerLink="/">Wróć</button>
          </div>
        </div>
      </div>
      </form>
  </div>
</div>
</div>
</div>


