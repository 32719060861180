<p-table #dt styleClass="p-datatable-sm mb-2 text-break" [columns]="cols" [lazy]="lazy" [showFirstLastIcon]="false"
    [showInitialSortBadge]="false" [showCurrentPageReport]="false" (onLazyLoad)="refreshGridLazy($event)"
    [scrollable]="false" [value]="data" [selectionMode]="selectMode" [(selection)]="selectedData" dataKey="id"
    [rows]="rowsPerPage" [rowsPerPageOptions]="[rowsPerPage,rowsPerPage*2,rowsPerPage*5]" [loading]="loading"
    [paginator]="true && totalRecords>rowsPerPage" [totalRecords]="totalRecords" [globalFilterFields]="search"
    (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)" class="p-datatable-responsive">
    <ng-template pTemplate="caption">
        <div class="tablebar">
            <label class="tableheader tabletitle">{{title}}</label>
            <ng-container *ngIf="data.length>0">
                <ng-container *ngIf="searchable && data.length>1">
                    <span class="spanmobile p-input-icon-right mr-1">
                        <i class="pi pi-search"></i>
                        <input class="inputmobile" [(ngModel)]="globalFilter" pInputText placeholder="Szukaj"
                            type="text" (input)="dt.filterGlobal($event.target.value, 'contains')" />
                    </span>
                </ng-container>
            </ng-container>
        </div>
    </ng-template>
    <ng-template pTemplate="header">
        <tr>
            <th *ngFor="let col of cols" [pSortableColumn]="sortable && data.length>1 ? col.field : null">
                {{col.header}}
                <p-sortIcon *ngIf="sortable && data.length>1" [field]="col.field"></p-sortIcon>
                <div *ngIf="filterable">
                    <p-columnFilter [type]="col.filter.type" field="{{col.field}}" [display]="col.filter.display"></p-columnFilter>
                </div>
            </th>                
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-data let-rowIndex="rowIndex">
        <tr [pSelectableRow]="data" [pSelectableRowIndex]="rowIndex">
            <td *ngFor="let col of cols">
                <span class="p-column-title">{{col.header}}</span>
                <ng-container [ngSwitch]="getColumnType(col.columnType, col.field)">
                    <span *ngSwitchCase="'decimal'">{{ data[col.field] ? data[col.field].toFixed(2) : '0.00' }}</span>
                    <span *ngSwitchCase="'bool'"><i class="pi" [ngClass]="{'true-icon pi-check-circle': data[col.field], 'false-icon pi-times-circle': !data[col.field]}"></i></span>
                    <span *ngSwitchCase="'datetime'">{{ data[col.field] | date:'dd.MM.yyyy HH:mm:ss' }}</span>
                    <span *ngSwitchCase="'icon'"><i [class]="data[col.field] | fileIcon"></i></span>
                    <span *ngSwitchCase="'date'">{{ data[col.field] | date:'dd.MM.yyyy' }}</span>
                    <span *ngSwitchCase="'month'">{{ getMonth(data[col.field]) }}</span>
                    <button *ngSwitchCase="'button'" pButton icon="pi pi-download" (click)="onColumnButtonClick(data)"></button>
                    <span *ngSwitchCase="'money'">{{ data[col.field] ? data[col.field].toFixed(2) : '0.00' }} PLN</span>
                    <span *ngSwitchCase="'percent'">{{ data[col.field] ? data[col.field].toFixed(2) : '0.00' }} %</span>
                    <span *ngSwitchCase="'nested'">{{ getNestedValue(data, col.field, col.columnType) }}</span>
                    <span *ngSwitchDefault>{{data[col.field]}}</span>
                  </ng-container>
            </td>
        </tr>
    </ng-template>    
    <ng-template pTemplate="emptymessage">
        <tr>
            <td style="font-style:italic" class="text-center" [attr.colspan]="cols.length">Brak rekordów</td>
        </tr>
    </ng-template>
    <ng-template pTemplate="summary">
        <ng-container *ngIf="exportable">
            <hr />
            <div class="text-right">
                <span style="cursor:pointer" (click)="exportPdf()">
                    <img class="mr-2" [src]="'/assets/ikony pozostale/pdf.svg'"><span>Generuj PDF</span>
                </span>
            </div>
        </ng-container>
    </ng-template>
</p-table>
